import { Component, Input } from '@angular/core';
import { Solar } from '@websolar/ng-websolar';

@Component({
    selector: 'app-legend',
    templateUrl: './legend.component.html',
    styleUrls: ['./legend.component.scss']
})
export class SolarLegendComponent {

    /**
     * The legend data to be displayed. This is an input property and it's required.
     */
    @Input() legend!: Solar.Legend;

    public getPercent(item: {
        min: number;
        max: number;
        color: string;
    }): number {
        if (!this.legend) {
            return 0;
        }
        const legendMax = this.legend.items[this.legend.items.length - 1].max;
        if (legendMax <= 0) {
            return 0;
        }
        return Math.round((item.max / legendMax) * 100);
    }


}
