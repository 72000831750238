<div class="component"
     *ngIf="info && project">

    <div class="main-block">
        <h3>{{"Project summary" | translate }}</h3>
        <div class="title-desc">{{project.name}}</div>

        <div class="block-wrapper summary-block">
            <div class="block">
                <div class="lbl">{{"System DC Power" | translate}}</div>
                <div class="val">
                    {{info.power}} {{"kWp" | translate}}
                </div>
            </div>

            <div class="divider"></div>

            <div class="block">
                <div class="lbl">{{"System Cost" | translate}}</div>
                <div class="val">
                    {{currency.sign}} {{info.cost}}
                </div>
            </div>

            <div class="divider"></div>

            <div class="block">
                <div class="lbl">{{"Payback" | translate}}</div>
                <div class="val">
                    {{info.payback}} {{'years' | translate}}
                </div>
            </div>

            <div class="divider"></div>

            <div class="block">
                <div class="lbl">{{"ROI" | translate}}</div>
                <div class="val">
                    {{info.ROI}}%
                </div>
            </div>
        </div>

        <div class="layout-wrapper"
             [class.full-view]="isViewExpanded">
            <app-report-design-layout [project]="project"
                                      [objects]="objects"
                                      (instanceReady)="onPreviewInstanceReady($event)"></app-report-design-layout>

            <div class="no-action-wrapper"
                 *ngIf="!isViewExpanded"></div>

            <div class="expander"
                 (click)="toggleView()">
                <img *ngIf="!isViewExpanded"
                     [src]="'icons/exander-view.png' | assetUrl">
                <mat-icon *ngIf="isViewExpanded"
                          color="primary">close</mat-icon>
            </div>
        </div>

    </div>

    <!-- Bill of materials -->
    <div class="main-block">
        <h3>{{"Bill Of Material" | translate}}</h3>

        <table mat-table
               class="bom-table"
               [dataSource]="bomSource">

            <ng-container matColumnDef="device">
                <th mat-header-cell
                    *matHeaderCellDef> {{"Device" | translate}} </th>
                <td mat-cell
                    *matCellDef="let element"> {{element.name | translate}} </td>
            </ng-container>

            <ng-container matColumnDef="model">
                <th mat-header-cell
                    *matHeaderCellDef> {{"Model" | translate}} </th>
                <td mat-cell
                    *matCellDef="let element"> {{element.model}} </td>
            </ng-container>

            <ng-container matColumnDef="quantity">
                <th mat-header-cell
                    *matHeaderCellDef> {{"Quantity" | translate}} </th>
                <td mat-cell
                    *matCellDef="let element"> {{element.quantity}} </td>
            </ng-container>

            <ng-container matColumnDef="price">
                <th mat-header-cell
                    *matHeaderCellDef> {{"Price" | translate}} </th>
                <td mat-cell
                    *matCellDef="let element"> {{element.price || 0}} </td>
            </ng-container>

            <ng-container matColumnDef="total">
                <th mat-header-cell
                    *matHeaderCellDef> {{"Total" | translate}} </th>
                <td mat-cell
                    *matCellDef="let element"> {{element.total}} </td>
            </ng-container>

            <tr mat-header-row
                *matHeaderRowDef="bomColumns"></tr>
            <tr mat-row
                *matRowDef="let row; columns: bomColumns;"></tr>
        </table>

        <div *ngIf="bomSource.length == 0"
             class="loading-text">
            {{"Loading" | translate}}...
        </div>
    </div>

    <!-- Financial analysis -->
    <div class="main-block">
        <h3>{{"FINANCIAL ANALYSIS" | translate }}</h3>

        <div class="main-block-wrapper">
            <div class="main-block-sidebar">
                <div class="center-block">
                    <div class="sub-title">
                        <span>{{"Cumulative" | translate}}</span>
                        <img [src]="'icons/question-mark.png' | assetUrl"
                             (mouseenter)="showNoteComulative=true"
                             (mouseleave)="showNoteComulative=false">
                    </div>
                    <div class="block-note">
                        <div *ngIf="showNoteComulative">
                            {{"Comulative saving for 30 years" | translate}}
                        </div>
                    </div>
                </div>
            </div>
            <div class="main-block-content">
                <div class="summary">
                    <div class="item">
                        <div class="text">{{"Bill Savings" | translate}}</div>
                        <div class="val">{{currency.sign}}{{info.savingsTotal}}</div>
                    </div>
                    <div class="symbol">
                        -
                    </div>
                    <div class="item">
                        <div class="text">{{"System Cost" | translate}}</div>
                        <div class="val">{{currency.sign}}{{info.cost}}</div>
                    </div>
                    <div class="symbol">
                        =
                    </div>
                    <div class="item"
                         [class.success]="info.savingsTotal > info.cost">
                        <div class="text">{{"Net Savings" | translate}}</div>
                        <div class="val">{{currency.sign}}{{info.savingsTotal - info.cost}}</div>
                    </div>
                </div>

                <div class="content-title">
                    {{"Annually Net Saving" | translate}}
                </div>

                <div class="net-saving">
                    <ngx-charts-bar-vertical [results]="netSaving"
                                             [customColors]="customColors"
                                             [xAxis]="true"
                                             [yAxis]="true"
                                             [showYAxisLabel]="true"
                                             [roundEdges]="false"
                                             [barPadding]="12"
                                             yAxisLabel="{{'Net Savings' | translate}} ({{currency.code}})">
                    </ngx-charts-bar-vertical>
                </div>
            </div>
        </div>

        <div class="line"></div>

        <div class="main-block-wrapper">
            <div class="main-block-sidebar">
                <div class="sub-title">
                    <span>{{"Monthly" | translate}}</span>
                </div>
            </div>
            <div class="main-block-content">

                <div class="content-title">
                    {{"Monthly Bill" | translate}}
                </div>

                <div class="block-wrapper">
                    <div class="block">
                        <div class="lbl">{{"Old Average Bill" | translate}}</div>
                        <div class="val old">
                            {{currency.sign}}{{info.oldBill}}
                        </div>
                    </div>

                    <div class="divider"></div>

                    <div class="block">
                        <div class="lbl">{{"New Average Bill" | translate}}</div>
                        <div class="val">
                            {{currency.sign}}{{info.newBill}}
                        </div>
                    </div>
                </div>

                <div class="line"></div>

                <div class="content-title">
                    {{"Monthly Bill Optmization" | translate}}
                </div>

                <app-report-bill-pipe [project]="project"
                                      [consumption]="consumption"></app-report-bill-pipe>
            </div>
        </div>

    </div>

    <div class="main-block">

        <h3>
            {{"PRODUCTION ANALYSIS" | translate}}
            <img [src]="'icons/question-mark.png' | assetUrl"
                 (mouseenter)="showNoteProduction=true"
                 (mouseleave)="showNoteProduction=false">
        </h3>

        <div class="main-block-wrapper top-note">
            <div class="main-block-sidebar">
                <div class="block-note">
                    <div *ngIf="showNoteProduction">
                        {{"Analysis for the first year" | translate}}
                    </div>
                </div>
                <div class="sub-title">
                    <span>{{"Annually" | translate}}</span>
                </div>
            </div>
            <div class="main-block-content">

                <div class="content-title">
                    {{"Annually Production Consumed" | translate}}
                </div>

                <div class="block">
                    <div class="lbl">{{"Energy Production" | translate}}</div>
                    <div class="val">
                        {{info.production}} {{"MWh" | translate}}
                    </div>
                </div>

                <div class="line">
                </div>

                <app-report-consumption-pipe [consumption]="consumption">
                </app-report-consumption-pipe>
            </div>
        </div>

        <div class="line"></div>

        <div class="main-block-wrapper">
            <div class="main-block-sidebar">
                <div class="sub-title">
                    <span>{{"Monthly" | translate}}</span>
                </div>
            </div>
            <div class="main-block-content">

                <div class="content-production-title">
                    <div class="title">
                        {{"Monthly Energy Consumption" | translate}}
                    </div>
                    <div class="legend">
                        <div class="item">
                            <div class="prod-marker"></div>
                            {{"Production" | translate}}
                        </div>
                        <div class="item">
                            <div class="consumption-marker"></div>
                            {{"Consumption" | translate}}
                        </div>
                        <div class="item">
                            <div class="solar-marker"></div>
                            {{"Solar Consumption" | translate}}
                        </div>
                    </div>
                </div>


                <app-report-production [project]="project"
                                       [consumption]="consumption"
                                       [events]="eventsAsObservable"
                                       [legendVisible]="false">
                </app-report-production>

            </div>
        </div>

    </div>

    <div class="main-block">
        <h3>{{"SYSTEM LOSSES" | translate}}</h3>
        <div class="main-block-wrapper">
            <div class="main-block-sidebar">
            </div>
            <div class="main-block-content">
                <app-report-losses [project]="project"
                                   [objects]="objects">
                </app-report-losses>
            </div>
        </div>
    </div>


</div>
