import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { BrowserModule } from '@angular/platform-browser';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { AppMaterialModule } from './app.material';
import { HttpClientModule } from '@angular/common/http';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { NgWebsolarModule } from '@websolar/ng-websolar';
import { NgxChartsModule } from '@swimlane/ngx-charts';


import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { ProjectListPageComponent } from './project/project-list-page/project-list-page.component';
import { ProjectEditPageComponent } from './project/project-edit-page/project-edit-page.component';
import { DesignPageComponent } from './rooftop/design-page/design-page.component';
import { RooftopSidebarComponent } from './rooftop/rooftop-sidebar/rooftop-sidebar.component';
import { PermitPanelComponent } from './permit/permit-panel/permit-panel.component';
import { AuthGuard } from './guards/auth.guard';
import { HostPageComponent } from './host-page/host-page.component';
import { SharePageComponent } from './share/share-page/share-page.component';
import { NavbarComponent } from './components/navbar/navbar.component';
import { AppReportModule } from './reports/report.module';
import { AppMountingModule } from './mounting/mounting.module';
import { AppSharedModule } from './app.shared.module';
import { MountingCalcService } from './services/mounting.calc.service';
import { QueryService } from './services/query.service';
import { AppPermitModule } from './permit/permit.module';
import { MetersListComponent } from './rooftop/meters-list/meters-list.component';
import { ConfirmComponent } from './components/confirm/confirm.component';
import { DialogService } from './services/dialog.service';
import { HttpService } from './services/http.service';
import { GeocodingService } from './services/geocoding.service';
import { MemoryService } from './services/memory.service';
import { GoogleMapService } from './services/google.map.service';
import { GoogleMapComponent } from './map/google/google-map.component';
import { PdfService } from './services/pdf.service';
import { GoogleLocationSearchComponent } from './components/location-search/google/google-location-search.component';
import { SegmentsListComponent } from './rooftop/segments-list/segments-list.component';
import { ShadowsOptionsComponent } from './rooftop/shadows-options/shadows-options.component';
import { TimePickerComponent } from './components/time-picker/time-picker.component';
import { SharingPanelComponent } from './share/share-panel/share-panel.component';
import { RooftopSidebarTreeComponent } from './rooftop/rooftop-sidebar-tree/rooftop-sidebar-tree.component';
import { BatteryPanelComponent } from './batteries/battery-panel/battery-panel.component';
import { InverterPickerComponent } from './rooftop/inverters/inverter-picker/inverter-picker.component';
import { InverterPickerWindowComponent } from './rooftop/inverters/inverter-picker-window/inverter-picker-window.component';
import { InverterEditWindowComponent } from './rooftop/inverters/inverter-edit-window/inverter-edit-window.component';
import { InvertersListComponent } from './rooftop/inverters/inverters-list/inverters-list.component';
import { OptimizerPickerWindowComponent } from './optimizers/optimizer-picker-window/optimizer-picker-window.component';
import { OptimizerPickerComponent } from './optimizers/optimizer-picker/optimizer-picker.component';
import { OptimizerService } from './services/optimizer.service';
import { OptimizerEditComponent } from './optimizers/optimizer-edit/optimizer-edit.component';
import { SettingsPannelComponent } from './settings-pannel/settings-pannel.component';
import { DigitsLimiterDirective } from './pipes/digits.limit.directive';
import { AmapComponent } from './map/amap/amap/amap.component';
import { MapComponent } from './map/map/map.component';
import { AmapLocationSearchComponent } from './components/location-search/amap-location-search/amap-location-search.component';
import { LocationSearchComponent } from './components/location-search/location-search/location-search/location-search.component';
import { AMapMapService } from './services/amap.map.service';
import { MapPickerComponent } from './rooftop/map-picker/map-picker.component';
import { MountingService } from './services/mounting.service';
import { CnTerrainCategoryHelpComponent } from './mounting/cn-terrain-category-help/cn-terrain-category-help.component';
import { SegmentOptionsComponent } from './rooftop/segments-list/segment-options/segment-options.component';
import { BatteryPickerComponent } from './batteries/battery-picker/battery-picker.component';
import { BatteryEditComponent } from './batteries/battery-edit/battery-edit.component';
import { BatteryWindowComponent } from './batteries/battery-window/battery-window.component';
import { ZoneMapComponent } from './mounting/zone-map/zone-map.component';
import { EUTerrainCategoryHelpComponent } from './mounting/eu-terrain-category-help/eu-terrain-category-help.component';
import { MainServicePanelListComponent } from './rooftop/main-service-panel/main-service-panel-list/main-service-panel-list.component';
import { MainServicePanelConfigComponent } from './rooftop/main-service-panel/main-service-panel-config/main-service-panel-config.component';
import { InvertersAddingComponent } from './rooftop/inverters/inverters-adding/inverters-adding.component';
import { InverterService } from './services/inverter.service';
import { InverterEditFieldsComponent } from './rooftop/inverters/inverter-edit-fields/inverter-edit-fields.component';
import { ModulePickerWindowComponent } from './rooftop/modules/module-picker-window/module-picker-window.component';
import { ModulePickerComponent } from './rooftop/modules/module-picker/module-picker.component';
import { ModuleEditWindowComponent } from './rooftop/modules/module-edit-window/module-edit-window.component';
import { TreeConfigNodeComponent } from './rooftop/main-service-panel/tree-config-node/tree-config-node.component';
import { ModuleConfigService } from './services/module.config.service';
import { InvertersMultiselectComponent } from './rooftop/inverters/inverters-multiselect/inverters-multiselect.component';
import { RoofListComponent } from './rooftop/roof-list/roof-list.component';
import { KeepoutListComponent } from './rooftop/keepout-list/keepout-list.component';
import { TreeListComponent } from './rooftop/tree-list/tree-list.component';
import { ToolbarComponent } from './toolbar/toolbar.component';
import { ArrayService } from './services/array.service';


@NgModule({
    declarations: [
        AppComponent,
        ProjectListPageComponent,
        ProjectEditPageComponent,
        DesignPageComponent,
        RooftopSidebarComponent,
        PermitPanelComponent,
        HostPageComponent,
        SharePageComponent,
        NavbarComponent,
        MetersListComponent,
        ConfirmComponent,
        GoogleMapComponent,
        GoogleLocationSearchComponent,
        SegmentsListComponent,
        ShadowsOptionsComponent,
        TimePickerComponent,
        SharingPanelComponent,
        RooftopSidebarTreeComponent,
        BatteryPanelComponent,
        InverterPickerComponent,
        InverterPickerWindowComponent,
        InverterEditWindowComponent,
        InvertersListComponent,
        OptimizerPickerWindowComponent,
        OptimizerPickerComponent,
        OptimizerEditComponent,
        SettingsPannelComponent,
        DigitsLimiterDirective,
        AmapComponent,
        MapComponent,
        AmapLocationSearchComponent,
        LocationSearchComponent,
        MapPickerComponent,
        CnTerrainCategoryHelpComponent,
        SegmentOptionsComponent,
        BatteryPickerComponent,
        BatteryEditComponent,
        BatteryWindowComponent,
        ZoneMapComponent,
        EUTerrainCategoryHelpComponent,
        MainServicePanelListComponent,
        MainServicePanelConfigComponent,
        InvertersAddingComponent,
        InverterEditFieldsComponent,
        ModulePickerWindowComponent,
        ModulePickerComponent,
        ModuleEditWindowComponent,
        TreeConfigNodeComponent,
        InvertersMultiselectComponent,
        RoofListComponent,
        KeepoutListComponent,
        TreeListComponent,
        ToolbarComponent
    ],
    imports: [
        BrowserModule,
        AppRoutingModule,
        BrowserAnimationsModule,
        ReactiveFormsModule,
        HttpClientModule,
        FormsModule,
        AppMaterialModule,
        CommonModule,
        NgWebsolarModule,
        NgxChartsModule,
        AppSharedModule,
        AppReportModule,
        AppMountingModule,
        AppPermitModule
    ],
    providers: [
        AuthGuard,
        MountingCalcService,
        ModuleConfigService,
        QueryService,
        DialogService,
        HttpService,
        GeocodingService,
        MemoryService,
        GoogleMapService,
        AMapMapService,
        PdfService,
        OptimizerService,
        MountingService,
        InverterService,
        ArrayService
    ],
    bootstrap: [AppComponent]
})
export class AppModule { }
