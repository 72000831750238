import * as THREE from "three";
import { Component, OnDestroy, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import {
    Solar,
    SolarInstance,
    WebSolarProjectService,
    NotifyService, WebSolarObjectService,
    WebSolarEventsService,
    WebSolarProjectStateService,
    WebSolarTransactionService,
    WebSolarModuleService,
    WebSolarInverterService,
    WebSolarOptimizerService,
    WebSolarWiringService,
    WebSolarGeometryService
} from '@websolar/ng-websolar';
import { ToolbarTool } from 'src/app/core/toolbar.tool';
import { assetUrl } from 'src/app/pipes/asset.url.pipe';
import { ArrayService } from 'src/app/services/array.service';
import { DialogService } from 'src/app/services/dialog.service';
import { AIKO } from 'src/app/types/aiko.types';

@Component({
    selector: 'app-design-page',
    templateUrl: './design-page.component.html',
    styleUrls: ['./design-page.component.scss']
})
export class DesignPageComponent implements OnInit, OnDestroy {

    public project!: Solar.Project;

    public objects!: Solar.Object[];

    public instance!: SolarInstance;

    public legend?: Solar.Legend;

    public mode: AIKO.MenuMode = "Design";

    /**
     * toolbar control
     */
    public toolbarControl: AIKO.ToolbarControl = {
        visible: true,
        save: true,
        autoModelling: false,
        copy: false,
        delete: false,
        reset: false,
        ruler: false,
        undoRedo: false,
        view3d: false,
        zoom: false
    }

    public isLoading = true;

    /**
     * The second hint on the screen
     */
    public hint2 = "";

    /**
     * The third hint on the screen
     */
    public hint3 = "";

    public fullWidthModes = [
        "Reports", "Mounting"
    ] as AIKO.MenuMode[];

    constructor(
        private _activatedRoute: ActivatedRoute,
        private _notify: NotifyService,
        private _eventService: WebSolarEventsService,
        private _projectService: WebSolarProjectService,
        private _objService: WebSolarObjectService,
        private _state: WebSolarProjectStateService,
        private _transactionService: WebSolarTransactionService,
        private _moduleService: WebSolarModuleService,
        private _inverterService: WebSolarInverterService,
        private _wsOptimizerService: WebSolarOptimizerService,
        private _dialogService: DialogService,
        private _wiringService: WebSolarWiringService,
        private _geometryService: WebSolarGeometryService,
        private _arrayService: ArrayService,
        private _geomService: WebSolarGeometryService
    ) { }

    /**
     * Initializes the component after Angular has initialized all data-bound properties.
     * This method is called right after the component's data-bound properties have been checked for the first time.
     * It is commonly used for initialization tasks such as retrieving data from a server.
     */
    public async ngOnInit() {
        try {
            const query = this._activatedRoute.snapshot.queryParams;

            let id = this._activatedRoute.snapshot.params["id"];
            if (!id) {
                // try get from query
                id = query["id"] as string;
            }

            if (!id) {
                throw `project id is not passed`;
            }

            // send the new project event
            this._eventService.events.next({ name: "new_project", params: null });

            // load objects first
            const objects = await this._objService.find({ projectId: id });

            // load project
            const project = await this._projectService.findOne(id);

            this.project = project;
            this.objects = objects;

            // run the pos initalization process
            this._projectService.postInitalize(this.project, this.objects);

            this.attachToEvents();
        }
        catch (err) {
            this._notify.error(err);
        }
    }

    public ngOnDestroy(): void {
        try {
            this._eventService.reset();
        }
        catch (err) {
            console.error(err);
        }
    }

    /**
     * Performs verification of the rooftop design by checking the modules, inverters, and optimizers.
     * If any of these objects are missing or deleted, it removes them from the drawing and prompts the user to choose new ones.
     */
    private async verification() {
        try {
            let deletedTypes: string[] = [];

            //
            // check modules
            //
            const segments = this.objects.filter(o => o.type == "segment") as Solar.ObjectRooftopSegment[];

            for (const segment of segments) {
                if (!segment.module) {
                    continue;
                }
                // load module from the db
                const modules = await this._moduleService.find({ id: segment.module._id });
                const module = modules[0];
                if (!module) {
                    if (!deletedTypes.includes("Module")) {
                        deletedTypes.push("Module");
                    }

                    // clear layout
                    this.instance.removeObjects({ ownerId: segment.id, types: ["module"] });
                }
                segment.module = module;
            }

            // 
            // Inverters
            //
            const invObjects = this.objects.filter(o => o.type == "inverter") as Solar.ObjectInverter[];
            for (const invObj of invObjects) {
                const invs = await this._inverterService.find({ id: invObj.inverter._id });
                const inv = invs[0];
                if (!inv) {
                    if (!deletedTypes.includes("Inverter")) {
                        deletedTypes.push("Inverter");
                    }
                    // clear the strings and configuration
                    this.instance.removeObjects({ types: ["string"] });
                    this.project.electrical.stringsConfig = {
                        items: [],
                        isAuto: false
                    };

                    // remove inverer from the drawing
                    this.instance.removeObjects({ id: [invObj.id] })
                }
            }

            // 
            // Optimizers
            //
            const optimizerObjects = this.objects.filter(o => o.type == "optimizer") as Solar.ObjectOptimizer[];
            for (const optimizerObj of optimizerObjects) {
                const optimizers = await this._wsOptimizerService.find({ id: optimizerObj.optimizer._id });
                const optimizer = optimizers[0];
                if (!optimizer) {
                    if (!deletedTypes.includes("Optimizer")) {
                        deletedTypes.push("Optimizer");
                    }
                    // clear the inverters settings
                    for (const invObj of invObjects) {
                        if (invObj.optimizer && invObj.optimizer._id == optimizerObj.optimizer._id) {
                            invObj.optimizer = undefined;
                        }
                    }
                    // remove the optimizer from the drawing
                    this.instance.removeObjects({ id: [optimizerObj.id] })
                }
            }

            // 
            // Show warns
            //
            for (const delType of deletedTypes) {
                await this._dialogService.confirm({
                    title: ``,
                    text: `The '${delType}' you selected has been deleted, please choose a new one`,
                    hideCancel: true
                })
            }
        }
        catch (err) {
            this._notify.error(err);
        }

    }

    /**
     * Callback function triggered when the SolarInstance is ready.
     * @param inst The SolarInstance object.
     */
    public onInstanceReady(inst: SolarInstance) {
        this.instance = inst;

        // activate the automodellin by default
        this._state.isAutoModeling = true;


        this.instance.transformControl.setCustomization({
            baseColor: "#ffffff",
            altColor: "#ffffff",
            highlightColor: "#46bc41",
            rotateImage: assetUrl("/icons/rotate-white.png"),
            rotateImageActive: assetUrl("/icons/rotate-white.png")
        })

        // run the project verification
        this.verification();

        console.log("instance ready")
    }

    /**
     * Callback function that is triggered when the load operation is completed.
     * Sets the isLoading flag to false.
     */
    public onLoadCompleted() {
        this.isLoading = false;
        this._eventService.send({ name: "project_loaded", params: null });
    }

    /**
     * Attaches event listeners to handle various events.
     */
    public attachToEvents() {
        this._eventService.eventsAsObservable.subscribe((opt) => {
            try {
                if (opt.name == "string_changed") {
                    this.detachString(opt.params as Solar.ObjectString);
                }
                else if (opt.name == "roof_edge_changed") {
                    this.cleanUpOnDesignChange(true);
                } else if (opt.name == "module_dialog_selected") {
                    this.cleanUpOnDesignChange(true);
                }
                else if (opt.name == "tool_completed") {

                    // cleanup hints
                    this.hint2 = "";
                    this.hint3 = "";

                    const args = opt.params as { type: Solar.ToolType; };
                    if (args.type == "keepout" ||
                        args.type == "keepout_line" ||
                        args.type == "segment") {
                        this.cleanUpOnDesignChange(true);
                    }
                    else if (args.type == "delete" || args.type == "copy") {
                        this.cleanUpOnDesignChange(false);
                    }
                }
                else if (opt.name == "object_changed") {
                    const changedObject = opt.params as Solar.Object;
                    if (changedObject.type == "module") {
                        this.handleModuleChanging(changedObject as Solar.ObjectModule);
                    }
                    else if (changedObject.type == "keepout" ||
                        changedObject.type == "keepout_line" ||
                        changedObject.type == "segment") {

                        const cleanUpWiring = this.isNeedCleanupWiring(changedObject);

                        this.cleanUpOnDesignChange(cleanUpWiring);
                    }
                }
                else if (opt.name == "object_deleted") {
                    const deletedObject = opt.params as Solar.Object;
                    if (deletedObject.type == "module" ||
                        deletedObject.type == "keepout" ||
                        deletedObject.type == "keepout_line" ||
                        deletedObject.type == "segment") {

                        const cleanUpWiring = this.isNeedCleanupWiring(deletedObject);
                        this.cleanUpOnDesignChange(cleanUpWiring);
                    }

                    if (deletedObject.type == "module") {
                        const module = deletedObject as Solar.ObjectModule;

                        if (module.optimizerId) {
                            // delete optimizer as well
                            this.instance.removeObjects({ id: module.optimizerId });
                        }
                    }

                    if (deletedObject.type == "inverter") {
                        // remove strings that belong to inverters
                        const flatTree = this._wiringService.getFlatTree(this.project.electrical.stringsConfig.items);
                        const invItem = flatTree.find(i => i.object.id == deletedObject.id);
                        if (invItem) {
                            const strsIds = this._wiringService.getFlatTree(invItem.children)
                                .filter(s => s.object.type == "string")
                                .map(s => s.object.id);
                            this.instance.removeObjects({ id: strsIds });
                        }

                        // sync config
                        this._wiringService.sync(this.instance, this.project);
                    }

                    if (deletedObject.type == "roof") {
                        this.deleteDependencies(deletedObject);
                    }
                }
                if (opt.name == "simulation_legend_ready") {
                    this.legend = opt.params as Solar.Legend;
                }

                if (opt.name == "project_saved") {
                    this._transactionService.clear();
                }


                if (opt.name == "line_tool_progress") {
                    // show 2 extra hints for roofs creation
                    this.hint2 = "Edit the length using the number keys";
                    this.hint3 = "Quickly create a right angle by using the Shift key";
                }
                else if (opt.name == "copy_tool_activated") {
                    // show extra hint
                    this.hint2 = "Edit x and y with number keys";
                }

            }
            catch (err) {
                console.error(err);
            }
        })
    }

    private handleModuleChanging(module: Solar.ObjectModule) {

        const res = this.instance.surface.getElevationWithFilter(module.position.x, module.position.y, { types: ["roof"] });
        const roof = res.object as Solar.ObjectRoof;
        if (!roof || roof.type != "roof") {
            // module placed outside roof
            this._dialogService.confirm({
                title: ``,
                text: `You can't place modules outside the roof`,
                hideCancel: true
            });

            // undo the action
            this.undoLastAction();
            return;
        }

        const segments = this.instance.getObjects({ types: ["segment"] }) as Solar.ObjectRooftopSegment[];
        const insideSegment = this._geometryService.isInside(module.position, segments);
        if (insideSegment) {
            if (module.owner != insideSegment.id) {
                // we are not allowed the module moving to the other arrays
                this._dialogService.confirm({
                    title: ``,
                    text: `There already exist modules`,
                    hideCancel: true
                });

                this.undoLastAction();
                return;
            }
        }
        else if (!insideSegment) {
            // create the new area
            //
            const newArray = this._arrayService.getNewArray(this.instance, this.project, roof.id, "array");
            const diagonal = new THREE.Vector3(module.width / 2, module.height / 2, 0).addScalar(0.2);
            const v1 = diagonal
                .clone()
                .applyAxisAngle(new THREE.Vector3(0, 0, 1), module.rotation?.z || 0)
                .add(new THREE.Vector3(module.position.x, module.position.y, 0));

            const v2 = diagonal
                .clone()
                .applyAxisAngle(new THREE.Vector3(0, 0, 1), (module.rotation?.z || 0) + Math.PI)
                .add(new THREE.Vector3(module.position.x, module.position.y, 0));

            // get the array points
            newArray.points = this._arrayService.getArrayPoints(this.instance, v1, v2, roof);
            // set azimuth
            this._geomService.setAutoAzimuth(newArray);

            if (!this._arrayService.verifyOverlapingAndPlacment(this.instance, newArray)) {
                this.undoLastAction();
                return;
            }

            // create new
            const obj3d = this.instance.createObject(newArray, this.project.measurement);
            if (obj3d) {
                this.instance.scene.add(obj3d);
            }

            // get the previous/original segment id
            const prevSegmentId = module.owner;

            // set the new owner
            module.owner = newArray.id;

            // check if we need to remove the previous array
            const modulesInPrev = this.instance.getObjects({ types: ["module"], ownerId: prevSegmentId });
            if (!modulesInPrev.length) {
                // remove the previous array
                this.instance.removeObjects({ id: prevSegmentId });
                this.instance.removeObjects({ ownerId: prevSegmentId });
            }

            this.instance.rebuild(this.project, [newArray.id]);
        }

        const cleanUpWiring = this.isNeedCleanupWiring(module);
        this.cleanUpOnDesignChange(cleanUpWiring);

        this._eventService.send({ name: "refresh", params: null });
    }

    private undoLastAction() {
        this._transactionService.undo(this.instance);
    }

    private isNeedCleanupWiring(deletedObject: Solar.Object) {
        let cleanUpWiring = true;
        if (deletedObject.type == "module") {
            cleanUpWiring = false;
        }
        else if (deletedObject.type == "segment") {
            if (!(deletedObject as Solar.ObjectRooftopSegment).module) {
                cleanUpWiring = false;
            }
        }
        return cleanUpWiring;
    }

    /**
     * If the number of modules in the string to which the MPPT has been associated changes, 
     * the string is automatically disconnected from the MPPT.
     * @param str 
     */
    public detachString(str: Solar.ObjectString) {
        const flatTree = this._wiringService.getFlatTree(this.project.electrical.stringsConfig.items);
        const mppts = flatTree.filter(t => t.object.type == "mppt");
        for (const mppt of mppts) {
            const idx = mppt.children.findIndex(c => c.object.id == str.id);
            if (idx >= 0) {
                mppt.children.splice(idx, 1);
            }
        }

        // remove optimizers as well
        const modules = this.instance.getObjects({ id: str.moduleIds }) as Solar.ObjectModule[];
        const optimizersIds = modules.filter(m => m.optimizerId).map(m => m.optimizerId || "");
        this.instance.removeObjects({ id: optimizersIds });
        for (const module of modules) {
            module.optimizerId = "";
        }
    }

    /**
     * Cleans up the project simulation and removes objects from the instance based on the design change.
     * @param clearWiring - A boolean indicating whether to clear the wiring or not.
     */
    private cleanUpOnDesignChange(clearWiring: boolean) {
        // mark the project simulation as not done
        this.project.simulationStatus = "";
        this.instance.removeObjects({ types: ["irradiance"] });

        if (clearWiring) {
            // clear inverters
            this.instance.removeObjects({ types: ["inverter"] });
            this.instance.removeObjects({ types: ["optimizer"] });
            this._wiringService.clearConfig(this.instance, this.project);
        }
    }

    /**
     * Handles the mode change event.
     * Clears the legend and updates the toolbar visibility based on the selected mode.
     */
    public onModeChange() {
        // clear legend
        this.legend = undefined;

        this.toolbarControl.visible = true;

        if (this.mode == "Reports") {
            this.toolbarControl.visible = false;
        }
        else if (this.mode == "KeepoutLines") {
            ToolbarTool.disableAll(this.toolbarControl);
            this.toolbarControl.save = true;
            this.toolbarControl.zoom = true;
            this.toolbarControl.view3d = true;
            this.toolbarControl.undoRedo = true;
            this.toolbarControl.delete = true;
        }
        else if (this.mode == "Array") {
            ToolbarTool.disableAll(this.toolbarControl);
            this.toolbarControl.save = true;
            this.toolbarControl.zoom = true;
            this.toolbarControl.view3d = true;
            this.toolbarControl.undoRedo = true;
            this.toolbarControl.delete = true;
            this.toolbarControl.copy = true;
        }
        else if (this.mode == "Battery") {

        }
        else if (this.mode == "Simulation") {
            this.toolbarControl.visible = false;
        }
        else if (this.mode == "Finance") {
            this.toolbarControl.visible = false;
        }
        else if (this.mode == "Mounting") {
            this.toolbarControl.visible = false;
        }
        else if (this.mode == "Inverters") {
            ToolbarTool.disableAll(this.toolbarControl);
            this.toolbarControl.save = true;
            this.toolbarControl.zoom = true;
            this.toolbarControl.view3d = true;
            this.toolbarControl.ruler = true;
        }

    }


    private deleteDependencies(object: Solar.Object) {
        const items = this.instance.getObjects({ ownerId: object.id });
        for (const item of items) {
            const subItems = this.instance.getObjects({ ownerId: item.id });
            if (subItems.length) {
                this.instance.removeObjects({ id: subItems.map(i => i.id) });
            }
            this.instance.removeObjects({ id: item.id });
        }

        if (object.type == "roof") {
            // delete inverters
            this.instance.removeObjects({ types: ["inverter"] });
            // clear editor
            this.instance.shapeEditor.clear();
        }
    }


}
