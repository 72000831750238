import { Injectable } from "@angular/core";
import { Solar, WebSolarReportService } from "@websolar/ng-websolar";


@Injectable()
export class FinanceService {

    constructor(
        private _reportService: WebSolarReportService
    ) { }

    public getSystemCost(project: Solar.Project, objects: Solar.Object[]) {
        const segmentInfo = this._reportService.getSegmentsInfoWithObjects(objects);
        const billSaving: { name: string, value: number }[] = [];
        const netSaving: { name: string, value: number }[] = [];

        let netCost = 0;
        let pricePerWatt = 1.5; // default

        if (project.finance &&
            project.finance.useCustomNetCost) {

            if (project.finance.mode == "pricePerDevice") {
                const bom = this._reportService.getBOM(project, objects);
                for (const record of bom) {
                    netCost += (record.total || 0);
                }
            }
            else {
                netCost = project.finance.customNetCost || 0;

                pricePerWatt = Math.round(netCost / (segmentInfo.power) / 10) / 100;
            }
        }
        else {
            // get default cost
            netCost = segmentInfo.power * 1000 * pricePerWatt;

            // add battery cost
            if (project.electrical.batteryCount &&
                project.electrical.battery) {
                // $0.7 per watt
                netCost += (project.electrical.battery.capacity * 1000 / 0.7) * project.electrical.batteryCount;
            }
        }

        if (project.finance && project.finance.taxRebate) {
            netCost = netCost * (1 - (project.finance.taxRebate / 100));
        }

        let payback = 0;
        // calculate the payback
        // get net savgings for 30 years
        const years = 30;
        const savings = this._reportService.getBillSavingsWithObjects(project, objects, years);

        let savingTotal = 0;
        let netValue = -netCost;
        let opexTotal = 0;

        // take 1% by default for OPEX
        const opexPerYear = netCost * 0.1;

        for (const saving of savings) {

            // cal total saving
            savingTotal += saving.value;
            // calc total OPEX
            opexTotal += opexPerYear;

            billSaving.push({
                name: saving.year.toString(),
                value: savingTotal
            })

            // add saving
            netValue += saving.value;

            // subsract OPEX
            netValue -= opexPerYear;

            if (netValue < 0) {
                // calc the payback period
                payback++;
            }

            netSaving.push({
                name: saving.year.toString(),
                value: netValue
            })
        }


        let ROI = 0;

        // calculate the ROI for 30 years
        // ROI = profit / (CAPEX + OPEX) in percent (%)
        if (netCost > 0) {
            ROI = Math.round(savingTotal / (netCost + opexTotal) * 100);
        }
        else {
            ROI = 0;
        }


        const consumption = this._reportService.getConsumptionOutput(project);

        // get estimated bill
        const oldTotalBill = consumption.months
            .reduce((prev, cur) => prev + cur.nonOptimizedBilling, 0);
        const oldBill = Math.round(oldTotalBill / 12);

        // get saving
        const totalSaving = consumption.months.reduce((prev, cur) => prev + cur.saving, 0)
        const newBill = Math.max(Math.round((oldTotalBill - totalSaving) / 12), 0);

        return {
            netCost: Math.round(netCost),
            pricePerWatt: pricePerWatt,
            payback: payback,
            ROI: ROI,
            savingsTotal: savingTotal,
            billSaving: billSaving,
            netSaving: netSaving,
            oldBill: oldBill,
            newBill: newBill
        };
    }
}