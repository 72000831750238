<div class="component"
     [class.hide-layout]="fullWidthModes.includes(mode)">
    <div class="sidebar">
        <app-rooftop-sidebar [instance]="instance"
                             [project]="project"
                             [toolbarControl]="toolbarControl"
                             [(mode)]="mode"
                             (modeChange)="onModeChange()"></app-rooftop-sidebar>
    </div>
    <div class="layout">
        <websolar-rooftop-layout [project]="project"
                                 [objects]="objects"
                                 [toolbar]="false"
                                 [mapControls]="false"
                                 [viewSwitched]="false"
                                 (instanceReady)="onInstanceReady($event)"
                                 (loadCompleted)="onLoadCompleted()"></websolar-rooftop-layout>

        <div class="toolbar-container"
             *ngIf="toolbarControl.visible">
            <app-toolbar [instance]="instance"
                         [project]="project"
                         [control]="toolbarControl"></app-toolbar>
        </div>

        <div *ngIf="hint2"
             class="hint hint-2">
            <mat-icon fontSet="material-icons-outlined">info</mat-icon>
            <div class="text">{{hint2 | translate}}</div>
            <mat-icon fontSet="material-icons-outlined"
                      (click)="hint2 = ''">close</mat-icon>
        </div>

        <div *ngIf="hint3"
             class="hint hint-3">
            <mat-icon fontSet="material-icons-outlined">info</mat-icon>
            <div class="text">{{hint3 | translate}}</div>
            <mat-icon fontSet="material-icons-outlined"
                      (click)="hint3 = ''">close</mat-icon>
        </div>

        <div class="map-picker-container">
            <app-map-picker [project]="project"></app-map-picker>
        </div>
    </div>
</div>



<div class="legend-container"
     *ngIf="legend">
    <app-legend [legend]="legend"></app-legend>
</div>

<div class="loading-wrapper"
     *ngIf="isLoading">
    <websolar-loading></websolar-loading>
</div>
