import { Component, Input, OnChanges, OnDestroy, Output } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { SolarInstance, Solar, NotifyService, WebSolarEventsService, WebSolarTransactionService, WebSolarGeometryService } from '@websolar/ng-websolar';
import { Subscription } from 'rxjs';
import { CloneTool } from 'src/app/core/clone.tool';
import { Geometry } from 'src/app/core/geometry';
import { ToolbarTool } from 'src/app/core/toolbar.tool';
import { ArrayService } from 'src/app/services/array.service';
import { DialogService } from 'src/app/services/dialog.service';
import { AIKO } from 'src/app/types/aiko.types';


@Component({
    selector: 'app-roof-list',
    templateUrl: './roof-list.component.html',
    styleUrls: ['./roof-list.component.scss']
})
export class RoofListComponent implements OnChanges, OnDestroy {

    @Input() project!: Solar.Project;

    @Input() instance!: SolarInstance;

    @Input() toolbarControl!: AIKO.ToolbarControl;

    public roofs: Solar.ObjectRoof[] = [];

    public itemState: { [key: string]: { expanded: boolean, item: Solar.ObjectRoof, customEdges: AIKO.RoofEdgeSettings[] } } = {};

    private _subs: Subscription[] = [];

    private _debounceTimer: unknown;

    private _originalRoofs: Solar.ObjectRoof[] = [];

    constructor(
        private _notify: NotifyService,
        private _dialogService: DialogService,
        private _eventService: WebSolarEventsService,
        private _translate: TranslateService,
        private _transactionService: WebSolarTransactionService,
        private _arrayService: ArrayService,
        private _geometryService: WebSolarGeometryService
    ) {
        // subscribe to events
        const sub = this._eventService.eventsAsObservable.subscribe((opt) => {

            if (opt.name == "project_loaded") {
                this.initOrigRoofsReference();
            }

            if (opt.name == "tool_completed" ||
                opt.name == "object_deleted" ||
                opt.name == "undo" ||
                opt.name == "redo" ||
                opt.name == "project_loaded") {
                this.updateList();

                if (opt.name == "undo" || opt.name == "redo") {
                    // workaround: fix the obstacles/keepouts adjustment
                    setTimeout(() => {
                        this.instance.rebuild(this.project);
                    }, 1);
                }
            }
            else if (opt.name == "object_changed") {
                this.onObjectChanged(opt.params as Solar.Object);
            }
            else if (opt.name == "object_picked") {
                this.onObjectPicked(opt.params as Solar.Object);
            }
            else if (opt.name == "roof_edge_activated") {
                this.onEdgeActivate(opt.params as Solar.RoofEdgeEvent);
            }
            else if (opt.name == "roof_edge_changed") {
                this.onEdgeChanged(opt.params as Solar.RoofEdgeEvent);
            }
        });
        this._subs.push(sub);
    }

    public ngOnChanges(): void {
        try {
            this.updateList();

            this.initOrigRoofsReference();
        }
        catch (err) {
            console.error();
        }
    }

    private initOrigRoofsReference() {
        if (this.instance && !this._originalRoofs.length) {
            // update the original reference
            this.updateOriginalRoofReference();
        }
    }

    public ngOnDestroy(): void {
        for (const sub of this._subs) {
            sub.unsubscribe();
        }
        if (this._debounceTimer) {
            clearTimeout(this._debounceTimer as number);
        }
    }

    private onObjectPicked(object: Solar.Object) {
        try {
            if (object.type != "roof") {
                return;
            }

            // collapse other segments
            for (const key of Object.keys(this.itemState)) {
                if (key != object.id) {
                    this.itemState[key].expanded = false;
                }
            }

            this.itemState[object.id].expanded = true;
        }
        catch (err) {
            console.error(err);
        }
    }

    /**
     * Handles the change event for an object.
     * If the object type is "roof", it removes arrays and modules that belong to the roof.
     * @param object - The object that has changed.
     */
    private onObjectChanged(object: Solar.Object) {
        try {
            if (object.type == "roof") {
                // remove arrays and modules that belong to roof
                this.handleRoofChanging(object as Solar.ObjectRoof);
            }
        }
        catch (err) {
            console.error(err);
        }
    }

    /**
    * Handles the changing of a roof.
    * @param roof - The new roof object.
    */
    public handleRoofChanging(roof: Solar.ObjectRoof) {
        // try get an old roof from the transactions
        const oldRoof = this._transactionService.getPreviousStateOfObject(roof.id) as Solar.ObjectRoof;
        if (!oldRoof) {
            console.error(`previous state of roof not found`);
            return;
        }
        if (!oldRoof.position) {
            console.error(`position of roof is not defined`);
            return;
        }


        const offset = { x: roof.position.x - oldRoof.position.x, y: roof.position.y - oldRoof.position.y };

        const changedObjects: Solar.Object[] = [];
        const origObjects: Solar.Object[] = [];

        // update the arrays and rebuild them
        const arrays = this.instance.getObjects({ types: ["segment"], ownerId: roof.id }) as Solar.ObjectRooftopSegment[];
        for (const arr of arrays) {
            // store the object for transaction
            origObjects.push(CloneTool.clone(arr));

            for (const pnt of arr.points) {
                pnt.x += offset.x;
                pnt.y += offset.y;
            }

            // update the plane
            arr.plane = undefined;
            this._geometryService.setAutoAzimuth(arr);

            // store the object for transaction
            changedObjects.push(CloneTool.clone(arr));

            // recreated the object
            this.instance.removeObjects({ id: arr.id });
            const obj3d = this.instance.createObject(arr, this.project.measurement);
            if (obj3d) {
                this.instance.scene.add(obj3d);
            }
        }

        // get keepouts lines and keepouts related to roof
        const keepouts = this.instance.getObjects({ types: ["keepout"] }) as Solar.ObjectKeepout[];
        for (const keepout of keepouts) {
            if (!keepout.position) {
                continue;
            }

            // check if it is inside the roof
            if (!this.isInsideRoof(keepout.position, oldRoof)) {
                continue;
            }

            // store the object for transaction
            origObjects.push(CloneTool.clone(keepout));

            // translate the object
            keepout.position.x += offset.x;
            keepout.position.y += offset.y;

            // store the object for transaction
            changedObjects.push(CloneTool.clone(keepout));
        }

        const keepoutsLines = this.instance.getObjects({ types: ["keepout_line"] }) as Solar.ObjectKeepoutLine[];
        for (const keepoutLine of keepoutsLines) {

            // check if it is inside the roof
            if (!this.isInsideRoof(keepoutLine.points[0], oldRoof) &&
                !this.isInsideRoof(keepoutLine.points[keepoutLine.points.length - 1], oldRoof)) {
                continue;
            }

            // store the object for transaction
            origObjects.push(CloneTool.clone(keepoutLine));

            for (const pnt of keepoutLine.points) {
                pnt.x += offset.x;
                pnt.y += offset.y;
            }

            // store the object for transaction
            changedObjects.push(CloneTool.clone(keepoutLine));
        }


        if (changedObjects.length) {
            // combine with the lasst transaction
            this._transactionService.combineWithLast({
                type: "changed",
                objects: changedObjects,
                previous: origObjects
            })

            // recreate objects on the scene
            for (const obj of changedObjects) {
                // recreated the object
                this.instance.removeObjects({ id: obj.id });
                const obj3d = this.instance.createObject(obj, this.project.measurement);
                if (obj3d) {
                    this.instance.scene.add(obj3d);
                }
            }
        }


        // rebuild the layouts
        this.instance.rebuild(this.project);
    }

    /**
     * Checks if a given position is inside a roof.
     * @param pos - The position to check.
     * @param roof - The roof object to check against.
     * @returns `true` if the position is inside the roof, `false` otherwise.
     */
    private isInsideRoof(pos: Solar.Point, roof: Solar.ObjectRoof): boolean {

        if (!roof.roofParts) {
            return false;
        }

        const roofTransform = this._geometryService.getObjectTransform(roof);
        for (const part of roof.roofParts) {
            const pg = this._geometryService.transformPoints(part.points, roofTransform);
            if (this._geometryService.isInsidePg(pos, pg)) {
                return true;
            }
        }

        return false;
    }

    private deleteRoofDependencies(object: Solar.ObjectRoof) {
        const items = this.instance.getObjects({ ownerId: object.id });
        for (const item of items) {
            const subItems = this.instance.getObjects({ ownerId: item.id });
            if (subItems.length) {
                this.instance.removeObjects({ id: subItems.map(i => i.id) });
            }
            this.instance.removeObjects({ id: item.id });
        }

        // delete inverters
        this.instance.removeObjects({ types: ["inverter"] });

        // clear editor
        this.instance.shapeEditor.clear();
    }

    private updateList() {
        try {
            if (!this.instance) {
                return;
            }
            const prevItems = this.roofs;

            this.roofs = this.instance.getObjects({ types: ["roof"] }) as Solar.ObjectRoof[];

            for (const item of this.roofs) {
                this.initObject(item);
            }

            if (prevItems && prevItems.length && prevItems.length < this.roofs.length) {
                // probably new item added
                const last = this.roofs[this.roofs.length - 1];
                this.itemState[last.id].expanded = true;
            }

            if (this.roofs.length == 1) {
                // expand it
                this.itemState[this.roofs[0].id].expanded = true;
            }

            ToolbarTool.disableAll(this.toolbarControl);

            if (this.roofs.length == 0) {
                this.instance.getHint().setMessage("Add the roof firstly,choose the right roof on the left side.");
                this.toolbarControl.save = true;
                this.toolbarControl.zoom = true;
            }
            else {
                this.instance.getHint().setMessage("");
                this.toolbarControl.save = true;
                this.toolbarControl.zoom = true;
                this.toolbarControl.view3d = true;
                this.toolbarControl.ruler = true;
                this.toolbarControl.reset = true;
                this.toolbarControl.autoModelling = true;
                this.toolbarControl.undoRedo = true;
            }
        }
        catch (err) {
            console.error();
        }
    }


    /**
     * Toggles the expanded state of a roof item and attaches the corresponding 3D object to the object editor.
     * @param roof - The roof object to toggle.
     */
    public toggle(roof: Solar.ObjectRoof) {
        if (!this.instance) {
            return;
        }

        this.itemState[roof.id].expanded = !this.itemState[roof.id].expanded;

        const obj3d = this.instance.get3dObjects({ id: roof.id })[0];
        if (obj3d) {
            this.instance.objectEditor.attach(obj3d);
        }
    }

    /**
     * Initializes an object with the given item.
     * @param item - The object roof item to initialize.
     */
    private initObject(item: Solar.ObjectRoof) {
        const customEdges: AIKO.RoofEdgeSettings[] = [];
        if (item.roofType == "custom" && item.customPoints) {
            for (let idx = 0; idx < item.customPoints.length - 1; idx++) {
                const settings = this.getEdgeSettings(item, idx, idx + 1);
                customEdges.push(settings);
            }
        }

        if (!item.name) {
            // set the roof name
            if (item.roofType == "flat") {
                item.name = this._translate.instant("Flat Roof");
            }
            else if (item.roofType == "gable") {
                item.name = this._translate.instant("Gable Roof");
            }
            else if (item.roofType == "hipped") {
                item.name = this._translate.instant("Hipped Roof");
            }
            else if (item.roofType == "pointy") {
                item.name = this._translate.instant("Pointy Roof");
            }
            else {
                item.name = this._translate.instant("Custom Roof");
            }
        }

        this.itemState[item.id] = {
            expanded: false,
            item: item,
            customEdges: customEdges
        };
    }

    private getEdgeSettings(roof: Solar.ObjectRoof, startIdx: number, endIdx: number): AIKO.RoofEdgeSettings {
        if (!roof.customPoints) {
            throw `customPoints are empty`
        }
        const p1 = roof.customPoints[startIdx];
        const p2 = roof.customPoints[endIdx];

        return {
            startIdx: startIdx,
            endIdx: endIdx,
            height: Math.round((p1.z + p2.z) / 2 * 10) / 10,
            length: Math.round(Geometry.getDistance(p1, p2) * 10) / 10,
            startHeight: Math.round(p1.z * 10) / 10,
            endHeight: Math.round(p2.z * 10) / 10
        }
    }

    /**
     * Calculates the area of a roof.
     * @returns The area of the roof.
     */
    public getArea(item: Solar.ObjectRoof): number {
        if (item.roofType == "custom") {
            if (!item.customPoints || !item.customPoints.length) {
                return 0;
            }
            return Math.round(Geometry.getArea(item.customPoints));
        }
        else {
            return Math.round((item.length || 0) * (item.width || 0));
        }
    }

    public async onDelete(item: Solar.ObjectRoof) {
        try {
            const confirm = await this._dialogService.confirm({
                title: `Delete roof`,
                text: `Are you sure you want to delete this roof?`,
                okBtn: "Delete"
            })
            if (!confirm) {
                return;
            }
            // remove item
            this.instance.removeObjects({
                id: item.id
            });

            // remove arrays and modules that belong to roof
            this.deleteRoofDependencies(item);

            this.updateList();

            this.instance.shapeEditor.clear();
        }
        catch (err) {
            this._notify.error(err);
        }
    }


    /**
     * Handles the property change event for a roof object.
     * 
     * @param roof - The roof object that has been changed.
     */
    public onPropertyChange(roof: Solar.ObjectRoof) {
        if (!this.instance) {
            return;
        }
        this.verifyRoofParameters(roof);

        // add the transaction first
        this.addTransaction();

        if (!this.verifyRoofsGeometry()) {
            return;
        }

        this.update3dRoofObject(roof);
    }

    /**
     * Adds a transaction for the changed roofs.
     * If there are original roofs, it creates a transaction with the updated roofs.
     * Updates the original reference with the current roofs.
     */
    private addTransaction() {

        if (this._originalRoofs.length) {
            const updatedRoofs = this.instance.getObjects({ types: ["roof"] })
                .filter(r => (r as Solar.ObjectRoof).roofType == "custom")
                .map(i => CloneTool.clone(i));

            this._transactionService.add({
                type: "changed",
                previous: this._originalRoofs,
                objects: updatedRoofs
            });
        }

        // update the original reference
        this.updateOriginalRoofReference();
    }

    /**
     * Verifies and updates the parameters of a roof object.
     * @param roof - The roof object to verify and update.
     */
    private verifyRoofParameters(roof: Solar.ObjectRoof) {
        if (roof.length) {
            roof.length = Math.max(roof.length, 0.01);
        }
        if (roof.width) {
            roof.width = Math.max(roof.width, 0.01);
        }
        if (roof.height) {
            roof.height = Math.max(roof.height, 0.01);
        }
        if (roof.fenceHeight) {
            roof.fenceHeight = Math.max(roof.fenceHeight, 0.01);
        }
        if (roof.fenceThickness) {
            roof.fenceThickness = Math.max(roof.fenceThickness, 0.01);
        }
        if (roof.ridgeHeight) {
            roof.ridgeHeight = Math.max(roof.ridgeHeight, 0.01);
        }
        if (roof.ridgeLength) {
            roof.ridgeLength = Math.max(roof.ridgeLength, 0.01);
        }
    }

    /**
     * Updates the 3D roof object in the drawing.
     * @param targetRoof - The target roof object to update.
     */
    private update3dRoofObject(targetRoof: Solar.ObjectRoof) {
        if (this._debounceTimer) {
            clearTimeout(this._debounceTimer as number);
            this._debounceTimer = 0;
        }
        this._debounceTimer = setTimeout(() => {
            if (!this.instance) {
                return;
            }
            // recreate a object on the drawing
            this.instance.removeObjects({ id: targetRoof.id });
            const newObj = this.instance.createObject(targetRoof, this.project.measurement);
            if (!newObj) {
                return;
            }
            this.instance.scene.add(newObj);

            const allRoofs = this.instance.getObjects({ types: ["roof"] }) as Solar.ObjectRoof[];
            for (const roof of allRoofs) {
                const roofSegments = this.instance.getObjects({ ownerId: roof.id, types: ["segment"] }) as Solar.ObjectRooftopSegment[];
                const hasAutoArrays = roofSegments.find(s => s.segmentType == "auto_array");
                if (hasAutoArrays) {
                    // rebuild the arrays
                    this._arrayService.runAutoplacement(roof, this.project, this.instance);

                    // delete inverters
                    this.instance.removeObjects({ types: ["inverter"] });
                    // clear editor
                    this.instance.shapeEditor.clear();
                }
                else {
                    // delete dependencies
                    this.deleteRoofDependencies(roof);
                }
            }

            this.instance.rebuild(this.project);
        }, 10);
    }

    /**
     * Creates a new roof with the specified roof type.
     * @param roofType The type of the roof.
     */
    public createNew(roofType: Solar.ObjectRoofType) {
        this.instance.activateTool({ type: "roof", params: { roofType: roofType } }, this.project);
    }

    public toggleEdge(roof: Solar.ObjectRoof, edge: AIKO.RoofEdgeSettings) {
        this.collapseCustomEdges();

        edge.highlight = true;
        edge.expanded = !edge.expanded;

        this.activateEdge(roof, edge);
    }

    public onEdgeChanged(options: Solar.RoofEdgeEvent) {
        if (!options.roof) {
            return;
        }
        setTimeout(() => {
            if (!this.verifyRoofsGeometry()) {
                return;
            }

            // find a roof in the list
            const itemState = this.itemState[options.roof.id];
            if (!itemState) {
                console.error(`roof not found in the list`);
                return;
            }
            // find a edge
            if (!itemState.customEdges) {
                return;
            }
            const customEdge = itemState.customEdges.find(e => e.startIdx == options.startIdx && e.endIdx == options.endIdx);
            if (!customEdge) {
                console.error(`edge not found`);
                return;
            }

            const settings = this.getEdgeSettings(options.roof, options.startIdx, options.endIdx);

            customEdge.endHeight = settings.endHeight;
            customEdge.startHeight = settings.startHeight;
            customEdge.length = settings.length;
            customEdge.height = settings.height;

            this.update3dRoofObject(options.roof);
        }, 1);
    }


    private verifyRoofsGeometry(): boolean {
        // get all roofs
        const roofs = this.instance.getObjects({ types: ["roof"] }) as Solar.ObjectRoof[];
        for (const roof of roofs) {
            if (!roof.customPoints?.length || roof.roofType != "custom") {
                continue;
            }

            // verify each segment
            const abstractSegment = this._arrayService.toAbstractSegment(roof);
            let isValid = this._geometryService.isValidSegment(abstractSegment);
            if (!isValid) {
                this._notify.error(`The operation will cause the roof to be abnormal`);

                // cancel the edge editor
                this.instance.edgeEditorRoof.disable();
                this.instance.edgeEditorRoof.enable();
                this.instance.edgeEditorRoof.cleanup();

                // restore the last editing
                this._transactionService.undo(this.instance);
                this._transactionService.clear();

                // update the original reference
                this.updateOriginalRoofReference();

                this.updateList();

                return false;
            }
        }

        return true;
    }

    private updateOriginalRoofReference() {
        this._originalRoofs = this.instance.getObjects({ types: ["roof"] })
            .filter(r => (r as Solar.ObjectRoof).roofType == "custom")
            .map(i => CloneTool.clone(i)) as Solar.ObjectRoof[];
    }

    public onEdgeActivate(options: Solar.RoofEdgeEvent) {
        if (!options.roof) {
            return;
        }
        // find a roof in the list
        const itemState = this.itemState[options.roof.id];
        if (!itemState) {
            console.error(`roof not found in the list`);
            return;
        }
        // find a edge
        if (!itemState.customEdges) {
            return;
        }
        const customEdge = itemState.customEdges.find(e => e.startIdx == options.startIdx);
        if (!customEdge) {
            console.error(`edge not found`);
            return;
        }
        // collapse others
        this.collapseCustomEdges();

        // exand the one
        customEdge.highlight = true;
        customEdge.expanded = true;
        itemState.expanded = true;
    }

    private collapseCustomEdges() {
        for (const key of Object.keys(this.itemState)) {
            const state = this.itemState[key];
            if (!state || !state.customEdges) {
                continue;
            }
            for (const edge of state.customEdges) {
                edge.highlight = false;
                edge.expanded = false;
            }
        }
    }

    public updateEdgeLength(roof: Solar.ObjectRoof, edge: AIKO.RoofEdgeSettings) {
        if (!roof.customPoints) {
            throw `customPoints are empty`
        }
        this.activateEdge(roof, edge);

        this.instance.edgeEditorRoof.setEdgeLength(edge.length, "center");

        this.update3dRoofObject(roof);
    }

    public updateEdgeHeight(roof: Solar.ObjectRoof, edge: AIKO.RoofEdgeSettings) {
        if (!roof.customPoints) {
            throw `customPoints are empty`
        }
        this.activateEdge(roof, edge);

        this.instance.edgeEditorRoof.setEdgeHeight(edge.height, "center");

        this.updateEdgeSettings(roof, edge);

        // add the transaction first
        this.addTransaction();

        if (!this.verifyRoofsGeometry()) {
            return;
        }

        this.update3dRoofObject(roof);
    }

    public updateEdgeStartHeight(roof: Solar.ObjectRoof, edge: AIKO.RoofEdgeSettings) {

        if (!roof.customPoints) {
            throw `customPoints are empty`
        }
        this.activateEdge(roof, edge);

        this.instance.edgeEditorRoof.setEdgeHeight(edge.startHeight, "start");

        this.updateEdgeSettings(roof, edge);

        // add the transaction first
        this.addTransaction();

        if (!this.verifyRoofsGeometry()) {
            return;
        }

        this.update3dRoofObject(roof);
    }

    public updateEdgeEndHeight(roof: Solar.ObjectRoof, edge: AIKO.RoofEdgeSettings) {

        if (!roof.customPoints) {
            throw `customPoints are empty`
        }
        this.activateEdge(roof, edge);

        this.instance.edgeEditorRoof.setEdgeHeight(edge.endHeight, "end");

        this.updateEdgeSettings(roof, edge);

        // add the transaction first
        this.addTransaction();

        if (!this.verifyRoofsGeometry()) {
            return;
        }

        this.update3dRoofObject(roof);
    }

    /**
     * Activates the specified edge for the given roof.
     * If the edge is not already active, it activates the edge for editing.
     * 
     * @param roof - The roof object.
     * @param edge - The edge settings to activate.
     */
    public activateEdge(roof: Solar.ObjectRoof, edge: AIKO.RoofEdgeSettings) {
        const activeEdge = this.instance.edgeEditorRoof.getActiveObject();
        if (!activeEdge ||
            activeEdge.roof.id != roof.id ||
            activeEdge.startIdx != edge.startIdx ||
            activeEdge.endIdx != edge.endIdx) {
            // activate the edge
            this.instance.edgeEditorRoof.activateEdgeEditing({ roof: roof, startIdx: edge.startIdx, endIdx: edge.endIdx });

            // collapse others
            this.collapseCustomEdges();

            // exand the one
            edge.highlight = true;
            edge.expanded = true;
            this.itemState[roof.id].expanded = true;
        }
    }

    private updateEdgeSettings(roof: Solar.ObjectRoof, edge: AIKO.RoofEdgeSettings) {
        const settings = this.getEdgeSettings(roof, edge.startIdx, edge.endIdx);
        edge.endHeight = settings.endHeight;
        edge.startHeight = settings.startHeight;
        edge.length = settings.length;
        edge.height = settings.height;
    }
}
