<div class="component">
    <div class="header">
        <div *ngIf="!inverter._id">
            {{"Create New Inverter" | translate}}
        </div>
        <div *ngIf="inverter._id">
            {{"Edit Inverter" | translate}}
        </div>
        <button mat-icon-button
                (click)="close()">
            <mat-icon>close</mat-icon>
        </button>
    </div>

    <div class="form"
         *ngIf="!isLoading">
        <mat-form-field>
            <mat-label>{{"Manufacturer" | translate}}</mat-label>
            <input matInput
                   [(ngModel)]="inverter.manufacturer">
        </mat-form-field>

        <mat-form-field>
            <mat-label>{{"Model" | translate}}</mat-label>
            <input matInput
                   [(ngModel)]="inverter.model">
        </mat-form-field>

        <mat-form-field>
            <mat-label>{{"Description" | translate}}</mat-label>
            <input matInput
                   [(ngModel)]="inverter.description">
        </mat-form-field>

        <mat-form-field>
            <mat-label>{{"Maximum Continuous Output Power at Unity Power Factor (kW)" | translate}}</mat-label>
            <input matInput
                   type="number"
                   [(ngModel)]="inverter.maxPower">
        </mat-form-field>

        <mat-form-field>
            <mat-label>{{"Nominal Voltage (Vac)" | translate}}</mat-label>
            <input matInput
                   type="number"
                   [(ngModel)]="inverter.nominal">
        </mat-form-field>

        <mat-form-field>
            <mat-label>{{"Weighted Efficiency (%)" | translate}}</mat-label>
            <input matInput
                   type="number"
                   [(ngModel)]="inverter.efficiency">
        </mat-form-field>

        <mat-form-field>
            <mat-label>{{"MPPT Minimum (Vdc)" | translate}}</mat-label>
            <input matInput
                   type="number"
                   [(ngModel)]="inverter.minVoltage">
        </mat-form-field>

        <mat-form-field>
            <mat-label>{{"MPPT Maximum (Vdc)" | translate}}</mat-label>
            <input matInput
                   type="number"
                   [(ngModel)]="inverter.maxVoltage">
        </mat-form-field>

        <mat-form-field>
            <mat-label>{{"Maximum Input (Vdc)" | translate}}</mat-label>
            <input matInput
                   type="number"
                   [(ngModel)]="inverter.maxInputDC">
        </mat-form-field>

        <mat-form-field>
            <mat-label>{{"Maximum PV Input Current (A)" | translate}}</mat-label>
            <input matInput
                   type="number"
                   [(ngModel)]="inverter.maxPVInputCurrent">
        </mat-form-field>

        <mat-form-field>
            <mat-label>{{"Battery DC Input (V)" | translate}}</mat-label>
            <input matInput
                   type="number"
                   [(ngModel)]="inverter.batteryVoltage">
        </mat-form-field>

        <mat-form-field>
            <mat-label>Number of MPPT</mat-label>
            <input matInput
                   type="number"
                   [(ngModel)]="inverter.numberMPPT">
        </mat-form-field>

        <mat-form-field>
            <mat-label>Strings per MPPT</mat-label>
            <input matInput
                   type="text"
                   [(ngModel)]="stringsPerMPPTFormatted">
        </mat-form-field>

        <div>
            <mat-checkbox [(ngModel)]="inverter.microinverter">{{"Microinverter" | translate}}</mat-checkbox>
        </div>

        <div>
            <mat-checkbox [(ngModel)]="inverter.hybrid">{{"Hybrid" | translate}}</mat-checkbox>
        </div>

        <div>
            <mat-checkbox [(ngModel)]="isFav">{{'Pin the device' | translate}}</mat-checkbox>
        </div>

        <div class="save-btn-wrapper">
            <button (click)="save()"
                    [disabled]="!isCanSave()"
                    mat-flat-button
                    color="primary">
                {{"Save Inverter" | translate}}
            </button>
        </div>


    </div>
    <div *ngIf="isLoading"
         class="loader-wrapper">
        <mat-spinner></mat-spinner>
    </div>
</div>
