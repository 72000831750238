import { Injectable } from "@angular/core";
import { Solar, SolarInstance, WebSolarInverterService, WebSolarWiringService } from "@websolar/ng-websolar";
import { AIKO } from "../types/aiko.types";


@Injectable()
export class InverterService {

    constructor(
        private _invServ: WebSolarInverterService,
        private _wireServWebS: WebSolarWiringService) { }

    /**
     * Builds a list of inverters based on the given capacity ratio and DC power.
     * 
     * @param capacityRatio - The capacity ratio as a percentage.
     * @param dcPower - The DC power.
    * @param filterIds - Optional array of filter IDs to apply.
     * @returns A promise that resolves to an array of objects containing the selected inverters and their counts.
     */
    public async buildInverters(capacityRatio: number, dcPower: number, filterIds?: string[]): Promise<{ inverter: Solar.Inverter, count: number }[]> {
        // load all inverters
        let inverters = await this._invServ.find({ limit: 1000 });
        if (filterIds && filterIds.length) {
            // filter inverters
            inverters = inverters.filter(i => filterIds.includes(i._id as string));
        }

        // filter out invalid inverters
        inverters = inverters.filter(i => i.numberMPPT && (i.numberMPPT || (i as AIKO.InverterExt).stringsPerMPPTLimits));

        // sort inverters by power
        inverters.sort((i1, i2) => i2.maxPower - i1.maxPower);

        const targetPower = dcPower / (capacityRatio / 100);

        const candidates: { inverters: { inverter: Solar.Inverter, count: number }[], capacityRatio: number }[] = [];
        for (let idx = 0; idx < inverters.length; idx++) {
            const testSet = inverters.slice(idx);

            const variants: { inverter: Solar.Inverter, mode: "ceil" | "floor" }[] = [];
            for (const inv of testSet) {
                variants.push({ inverter: inv, mode: "ceil" });
                variants.push({ inverter: inv, mode: "floor" });
            }

            const combinations = this.getAllCombinations(variants);

            for (let combination of combinations) {

                // sort inverters
                combination.sort((i1, i2) => i2.inverter.maxPower - i1.inverter.maxPower);

                const solution = this.getSolution(targetPower, combination)
                if (solution.length) {
                    candidates.push({
                        inverters: solution,
                        capacityRatio: 0
                    })
                }
            }
        }

        if (!candidates.length) {
            return [];
        }


        // calculate the capacity ratio
        for (const candidate of candidates) {
            const total = candidate.inverters.reduce((prev, cur) => cur.inverter.maxPower * cur.count + prev, 0);
            candidate.capacityRatio = (dcPower / total) * 100
        }

        // sort by best
        candidates.sort((s1, s2) => {
            let diff = Math.abs(s1.capacityRatio - s2.capacityRatio);
            if (diff < 0.01) {
                // pick with the highest power from the first
                // pick by count of inverters, less count than higer power
                const count1 = s1.inverters.reduce((prev, cur) => prev + cur.count, 0);
                const count2 = s2.inverters.reduce((prev, cur) => prev + cur.count, 0);
                return count1 - count2;
            }
            else {
                const diff1 = Math.abs(capacityRatio - s1.capacityRatio);
                const diff2 = Math.abs(capacityRatio - s2.capacityRatio);
                return diff1 - diff2;
            }
        })


        // solution not found
        // return the first one
        return candidates[0].inverters;
    }


    /**
     * Builds the wiring configuration for the inverters based on the provided options.
     * 
     * @param options - The options for building the wiring configuration.
     * @param options.instance - The solar instance.
     * @param options.project - The solar project.
     * @returns An array of InverterWiring objects representing the wiring configuration.
     */
    public buildWiring(options: {
        targetRatio: number,
        instance: SolarInstance,
        project: Solar.Project,
        segmentIds: string[],
        inverterObjectsIds: string[]
    }): Solar.InverterWiring[] {
        // get the initial wiring
        const output: Solar.InverterWiring[] = this.getInvertersWiring(options);

        // get segments
        let segments = options.instance.getObjects({
            id: options.segmentIds,
            types: ["segment"]
        }) as Solar.ObjectRooftopSegment[];

        // filter segements
        segments = segments.filter(s => s.module && s.output);

        if (!segments.length) {
            return [];
        }

        const segmentsIds = segments.map(s => s.id);
        const allModules: Solar.ObjectModule[] = [];

        for (const segment of segments) {
            // get modules
            const modules = options.instance.getObjects({
                types: ["module"],
                ownerId: segment.id
            }) as Solar.ObjectModule[];

            if (!modules.length) {
                continue;
            }
            allModules.push(...modules);
        }

        if (allModules.length) {
            this.buildWiringAtSegments(options.targetRatio, segmentsIds, allModules, output);
        }

        return output;
    }


    private getSolution(targetPower: number, combination: { inverter: Solar.Inverter, mode: "ceil" | "floor" }[]) {
        let output: { inverter: Solar.Inverter, count: number }[] = [];

        for (let entry of combination) {

            const inv = entry.inverter;

            let count: number;
            if (entry.mode == "ceil") {
                count = Math.ceil(targetPower / inv.maxPower);
            }
            else {
                count = Math.floor(targetPower / inv.maxPower);
            }

            count = Math.max(count, 1);

            const power = (count * inv.maxPower);

            targetPower -= power;

            output.push({
                inverter: inv,
                count: count
            })

            if (targetPower <= 0) {
                break;
            }
        }

        return output;
    }

    private buildWiringAtSegments(
        targetRatio: number,
        segmentIds: string[],
        modules: Solar.ObjectModule[],
        invertersWirings: Solar.InverterWiring[]
    ) {
        if (!modules.length || !invertersWirings.length) {
            // empty input
            return;
        }

        const modulePower = modules[0].modulePower;
        let leftModules = modules.length;

        for (const invWiring of invertersWirings) {
            if (invWiring.segmentIds &&
                invWiring.segmentIds.length &&
                !invWiring.segmentIds.includes(segmentIds[0])) {
                // Inverter can be belong to the consistent segments only (same roof)
                continue;
            }

            const condition = invWiring.condition;
            if (!condition ||
                !condition.recommendedStringSize ||
                !condition.maxStringSize ||
                !condition.minStringSize) {
                // condition is not available
                continue;
            }

            const maxStringsCount = invWiring.mppt.reduce((prev, cur) => cur.allowed + prev, 0);
            if (!maxStringsCount) {
                console.error(`solution not found for inverter`);
                continue;
            }

            if (leftModules < condition.minStringSize) {
                // no solution
                continue;
            }


            if (invertersWirings.length == 1) {
                const maxAllowedModules = invWiring.mppt.reduce((prev, cur) => (cur.allowed * condition.maxStringSize) + prev, 0);
                if (leftModules > maxAllowedModules) {
                    for (let idx = 0; idx < maxStringsCount; idx++) {
                        const targetMppt = this.getMppt(invWiring, condition.maxStringSize);
                        if (!targetMppt) {
                            break;
                        }

                        targetMppt.allocatedStrings.push(condition.maxStringSize);
                    }

                    // reduce left modules
                    const allocatedCount = invWiring.mppt.reduce((prev, cur) => cur.allocatedStrings.length + prev, 0);
                    leftModules -= allocatedCount;

                    invWiring.segmentIds = segmentIds;

                    continue;
                }
            }

            let countByRatio = Math.round(((targetRatio / 100) * invWiring.inverter.maxPower * 1000) / modulePower);
            if (countByRatio == 0) {
                // we can't allocate strings
                continue;
            }

            // we should limit the count to max string size
            let maxPossible = 0;
            for (const mppt of invWiring.mppt) {
                maxPossible += mppt.allowed * condition.maxStringSize;
            }
            if (countByRatio > maxPossible) {
                countByRatio = maxPossible;
            }

            let modulesToConnect = countByRatio < leftModules ? countByRatio : leftModules;

            let sol1 = Math.floor(modulesToConnect / condition.recommendedStringSize);
            let reaminer1 = modulesToConnect - (sol1 * condition.recommendedStringSize);
            let avg1 = Math.abs(reaminer1 / sol1);

            let sol2 = Math.ceil(modulesToConnect / condition.recommendedStringSize);
            let reaminer2 = modulesToConnect - (sol2 * condition.recommendedStringSize);
            let avg2 = Math.abs(reaminer2 / sol2);

            let sol = 0;
            let remainer = 0;
            let stringSize = condition.recommendedStringSize;
            if (sol1 > maxStringsCount && sol2 > maxStringsCount) {
                // define the new string size
                stringSize = Math.floor(modulesToConnect / maxStringsCount);
                sol = maxStringsCount;
                remainer = modulesToConnect - (sol * stringSize);
            }
            else if (sol2 > maxStringsCount || avg1 < avg2) {
                // pick the solution 1
                sol = sol1;
                remainer = reaminer1;
            }
            else {
                // pick the solution 2
                sol = sol2;
                remainer = reaminer2;
            }

            for (let idx = 0; idx < sol; idx++) {
                const targetMppt = this.getMppt(invWiring, stringSize);
                if (!targetMppt) {
                    break;
                }

                targetMppt.allocatedStrings.push(stringSize);
            }



            if (remainer != 0) {
                // try add more modules to each mppt
                let added = true;
                while (added && remainer != 0) {
                    added = false;
                    for (let mppt of invWiring.mppt) {
                        if (!mppt.allocatedStrings.length) {
                            continue;
                        }
                        if (!remainer) {
                            break;
                        }
                        if (Math.abs(remainer) >= mppt.allocatedStrings.length) {
                            for (let idx = 0; idx < mppt.allocatedStrings.length; idx++) {
                                if (remainer > 0) {
                                    mppt.allocatedStrings[idx]++;
                                    remainer--;
                                }
                                else {
                                    mppt.allocatedStrings[idx]--;
                                    remainer++;
                                }
                                added = true;
                            }
                        }
                    }
                }
            }

            invWiring.segmentIds = segmentIds;

            // update the number of modules
            let allocatedCount = 0;
            for (const mppt of invWiring.mppt) {
                for (let count of mppt.allocatedStrings) {
                    allocatedCount += count;
                }
            }
            leftModules -= allocatedCount;
        }
    }


    private getMppt(invWiring: Solar.InverterWiring, stringSize: number): Solar.MpptWiring | null {
        let targetMppt: Solar.MpptWiring | null = null;

        for (const mppt of invWiring.mppt) {
            if (mppt.allocatedStrings.length) {
                continue;
            }
            targetMppt = mppt;
            break;
        }
        if (!targetMppt) {
            for (const mppt of invWiring.mppt) {
                if (mppt.allocatedStrings.length >= mppt.allowed) {
                    continue;
                }
                // the string should be consistent for MPPT
                const allocatedStringSize = mppt.allocatedStrings[0];
                if (allocatedStringSize == stringSize) {
                    targetMppt = mppt;
                    break;
                }
            }
        }

        return targetMppt;
    }

    /**
     * Retrieves the wiring configuration for the inverters.
     * 
     * @param inverters - An array of inverters or undefined.
     * @param project - The solar project.
     * @returns An array of InverterWiring objects.
     * @throws Throws an error if inverters are not available on the design.
     */
    private getInvertersWiring(options: {
        instance: SolarInstance,
        project: Solar.Project
        inverterObjectsIds: string[]
    }): Solar.InverterWiring[] {
        let invObjects: Solar.ObjectInverter[] = [];
        invObjects = options.instance.getObjects({ id: options.inverterObjectsIds,  types: ["inverter"] }) as Solar.ObjectInverter[];
        const inverters = invObjects.map(d => (d as Solar.ObjectInverter).inverter as AIKO.InverterExt);

        if (!inverters || !inverters.length) {
            throw `inverters are not available on the design`;
        }

        // sort inverters by power
        inverters.sort((i1, i2) => i2.maxPower - i1.maxPower);

        // prepare the usage
        const invertersWiring: Solar.InverterWiring[] = [];

        for (const inverter of inverters) {

            const mpptSpec: Solar.MpptWiring[] = [];

            if (!inverter.numberMPPT) {
                throw `number of MPPT not defined for inverter "${inverter.model}"`
            }

            if (!inverter.stringsPerMPPT &&
                !inverter.stringsPerMPPTLimits
            ) {
                throw `number of String per mppt not defined`
            }

            const numberMPPT = inverter.numberMPPT;

            for (let idx = 0; idx < numberMPPT; idx++) {
                let stringsPerMPPT = 0;
                if (inverter.stringsPerMPPTLimits && inverter.stringsPerMPPTLimits.length) {
                    stringsPerMPPT = inverter.stringsPerMPPTLimits[idx] || 0;
                }
                else {
                    stringsPerMPPT = inverter.stringsPerMPPT || 0;
                }

                mpptSpec.push({
                    stringsObjects: [],
                    allowed: stringsPerMPPT,
                    allocatedStrings: []
                });
            }

            invertersWiring.push({
                inverter: inverter,
                condition: this._wireServWebS.calcCondition(options.instance, options.project),
                mppt: mpptSpec,
                object: invObjects.find(i => i.inverter == inverter),
                segmentIds: []
            });
        }
        return invertersWiring;
    }


    private getAllCombinations<T>(array: T[]): T[][] {
        // Base case: if the array is empty, return an array containing an empty array
        if (array.length === 0) {
            return [[]];
        }

        // Recursive case: remove the first element from the array
        const firstElement = array[0];
        const rest = array.slice(1);

        // Get all combinations of the rest of the array
        const combinationsWithoutFirst = this.getAllCombinations(rest);
        const combinationsWithFirst = [];

        // For each combination already found, add the first element to it
        for (const combination of combinationsWithoutFirst) {
            const combinationWithFirst = [firstElement, ...combination];
            combinationsWithFirst.push(combinationWithFirst);
        }

        // Return the combination of those with and without the first element
        return [...combinationsWithoutFirst, ...combinationsWithFirst];
    }

}
