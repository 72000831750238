import { Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { CustomizationService, NotifyService, WebSolarObjectService, WebSolarPermitService, WebSolarProjectService, Solar } from '@websolar/ng-websolar';
import { PdfService } from 'src/app/services/pdf.service';
import { AIKO } from 'src/app/types/aiko.types';

@Component({
    selector: 'app-permit-page',
    templateUrl: './permit-page.component.html',
    styleUrls: ['./permit-page.component.scss']
})
export class PermitPageComponent implements OnInit {

    @ViewChild("contentElement") _contentElement!: ElementRef<HTMLElement>;

    /**
     * Indicates whether the component is currently loading.
     */
    public isLoading = false;

    /**
     * Indicates whether the component is currently exporting.
     */
    public isExporting = false;

    /**
     * The email address of the user.
     */
    public email = "";

    /**
     * The permit scope for the permit page component.
     */
    public permitScope!: AIKO.PermitScope;

    /**
     * Represents the pages in the permit page component.
     * Each page has a name and an optional segment.
     */
    public pages: { name: string, segment?: Solar.ObjectRooftopSegment }[] = [];

    constructor(
        private _activatedRoute: ActivatedRoute,
        private _notify: NotifyService,
        private _router: Router,
        private _customization: CustomizationService,
        private _projectService: WebSolarProjectService,
        private _objectService: WebSolarObjectService,
        private _permitService: WebSolarPermitService,
        private _translate: TranslateService,
        private _pdfService: PdfService
    ) { }

    ngOnInit() {
        this.load();
    }

    private async load() {
        try {
            this.isLoading = true;
            const id = this._activatedRoute.snapshot.params["id"];
            if (!id) {
                throw `id is empty`
            }

            try {
                const customization = await this._customization.find();
                this.email = customization.supportEmail;
            }
            catch (err) {
                console.error(err);
            }

            const project = await this._projectService.findOne(id);
            if (!project) {
                throw `project not found`
            }
            const objects = await this._objectService.find({ projectId: id });

            this._projectService.postInitalize(project, objects);
            
            const content = await this._permitService.build({ projectId: id, lang: this._translate.currentLang });

            this.permitScope = {
                project: project,
                objects: objects,
                content: content
            }

            this.buildPages();
        }
        catch (err) {
            this._notify.error(err);
        }
        finally {
            this.isLoading = false;
        }
    }

    /**
     * Exports the content of the component to a PDF file.
     * @returns {Promise<void>} A promise that resolves when the export is complete.
     */
    public async exportToPdf() {
        this.isExporting = true;

        // give some time for rendering the loading UI 
        setTimeout(async () => {
            try {
                const elementsRef = (this._contentElement.nativeElement as HTMLElement).getElementsByClassName("pdf-page")
                const elements = Array.from(elementsRef) as HTMLElement[];

                await this._pdfService.export({
                    filename: `${this.permitScope.project.name}.pdf`,
                    inputs: elements,
                    pageWidth: 1800,
                    // the height should be equal to heighe defined in the class .pdf-page
                    pageHeight: 950
                });
            }
            catch (err) {
                this._notify.error(err);
            }
            finally {
                this.isExporting = false;
            }
        }, 100);
    }

    private buildPages() {
        this.pages = [
            { name: "summary" },
            { name: "site-plan" },
            { name: "bom" },
            { name: "sld" }
        ];

        const segments = this.permitScope.objects.filter(o => o.type == "segment") as Solar.ObjectRooftopSegment[];
        for (const segment of segments) {
            if (!segment.module) {
                continue;
            }

            this.pages.push({
                name: "attachment",
                segment: segment
            })
        }

        this.pages.push({ name: "material-mounting" });
        this.pages.push({ name: "safety-signs" });
    }

    public print() {
        window.print();
    }

    public back() {
        if (this.permitScope) {
            this._router.navigate([`/design/${this.permitScope.project._id}`]);
        }
        else {
            this._router.navigate(["/project/list"]);
        }
    }
}
