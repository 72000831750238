import { Solar, SolarInstance } from "@websolar/ng-websolar";
import * as THREE from "three";
import { AikoModelStorage } from "./aiko.model.storage";

export class OptimizerFactory implements Solar.CustomObjectFactory {

    public getType(): Solar.ObjectType {
        return "optimizer";
    }

    public create(obj: Solar.Object, units?: Solar.Units): THREE.Object3D | null {
        if (!obj || obj.type != "optimizer") {
            return null;
        }
        return this.createOptimizer(obj as Solar.ObjectOptimizer)
    }

    private createOptimizer(optimizer: Solar.ObjectOptimizer) {
        const group = new THREE.Group();

        const meshes = this.createMeshes();

        const zOffset = 0.3;

        // apply transformation
        if (optimizer.rotation) {
            for (const mesh of meshes) {
                const eauler = new THREE.Euler(optimizer.rotation.x, optimizer.rotation.y, optimizer.rotation.z);
                mesh.applyQuaternion(new THREE.Quaternion().setFromEuler(eauler))
            }
        }

        // add meshes to group
        group.add(...meshes);

        if (optimizer.connectionPoints && optimizer.connectionPoints.length > 1) {
            // get relative points positions
            const points: Solar.Point[] = [];
            for (const pnt of optimizer.connectionPoints) {
                points.push({ x: pnt.x - optimizer.position.x, y: pnt.y - optimizer.position.y, z: pnt.z - optimizer.position.z });
            }
            // create line
            const line = this.createLine(points, "#ffffff");
            group.add(line);
        }

        // assign data
        group.userData = optimizer;
        group.position.set(optimizer.position.x, optimizer.position.y, optimizer.position.z + zOffset);

        return group;
    }


    private createMeshes(): THREE.Mesh[] {
        const group = AikoModelStorage.getObject("optimizer-aiko") as THREE.Group;
        const meshes = group.children as THREE.Mesh[];
        return meshes;
    }


    private createLine(source: Solar.Point[], lineColor: string) {
        const points: THREE.Vector3[] = [];
        for (const p of source) {
            points.push(new THREE.Vector3(p.x, p.y, p.z));
        }

        const geometry = new THREE.BufferGeometry().setFromPoints(points);

        const material = new THREE.LineBasicMaterial({ color: lineColor, side: THREE.DoubleSide });
        const line = new THREE.Line(geometry, material);

        line.geometry = geometry;
        line.geometry.computeBoundingBox();
        line.geometry.computeBoundingSphere();
        return line;
    }


}