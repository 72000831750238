<div class="component">
    <h2 class="title">
        <!-- <app-pro-badge></app-pro-badge> -->
        {{"Online Proposal" | translate}}
    </h2>

    <ng-container *ngIf="isPremium">
        <div>
            {{"Share this link with anyone who you want to give access to view your project." | translate}}
        </div>

        <div class="loader"
             *ngIf="isLoading">
            <mat-spinner></mat-spinner>
        </div>

        <ng-container *ngIf="!isLoading">
            <mat-form-field>
                <mat-label>{{"Link" | translate}}</mat-label>
                <input matInput
                       [ngModel]="link"
                       readonly="readonly">
                <button matSuffix
                        mat-icon-button
                        [matTooltip]="'Copy link' | translate"
                        color="primary"
                        (click)="copyToClipboard()">
                    <mat-icon fontSet="material-icons-outlined">copy</mat-icon>
                </button>
                <mat-hint>
                    {{"Your project is viewable to anyone who has this link" | translate}}
                </mat-hint>
            </mat-form-field>
        </ng-container>
    </ng-container>

    <ng-container *ngIf="!isPremium">
        <div>
            {{"Upgrade to Premium and unlock the ability to share online proposal with anyone." | translate}}
        </div>
    </ng-container>
</div>
