import { Component, EventEmitter, Input, OnChanges, Output, SimpleChanges } from '@angular/core';
import { Solar, SolarInstance, NotifyService, WebSolarConfiguration } from '@websolar/ng-websolar';
import { assetUrl } from 'src/app/pipes/asset.url.pipe';
import { AIKO } from 'src/app/types/aiko.types';

interface MenuItem {

    name: AIKO.MenuMode;

    title: string;

    active?: boolean;

    icon?: string;

    svgIcon?: string;

    pngIcon?: string;

    children?: MenuItem[];

    expanded?: boolean;
}


@Component({
    selector: 'app-rooftop-sidebar-tree',
    templateUrl: './rooftop-sidebar-tree.component.html',
    styleUrls: ['./rooftop-sidebar-tree.component.scss']
})
export class RooftopSidebarTreeComponent implements OnChanges {

    @Input() project?: Solar.Project;

    @Input() instance?: SolarInstance;

    @Input() mode!: AIKO.MenuMode;

    @Input() hideBack = false;

    @Output() itemActivated = new EventEmitter<AIKO.MenuMode>();

    @Output() back = new EventEmitter<void>();

    @Input() menuItems: MenuItem[] = [
        {
            name: "Design", title: "Design", icon: "roofing", active: true, expanded: true,
            children: [
                { name: "Obstacles", title: "Obstacles", svgIcon: `${WebSolarConfiguration.storageUrl}/images/chimney.svg` },
                { name: "Trees", title: "Occlusion", icon: "park" },
                { name: "KeepoutLines", title: "Keepouts", icon: "block" }
            ]
        },
        { name: "Array", title: "Array", svgIcon: assetUrl("icons/array.svg") },
        {
            name: "Inverters", title: "Electrical", icon: "power", active: true, expanded: true,
            children: [
                { name: "MainServicePanel", title: "Service Panel", icon: "bolt" },
                { name: "ACDisconnect", title: "AC Disconnect", icon: "offline_bolt" },
                { name: "Meters", title: "Meters", icon: "electric_meter" },
                { name: "CableTrays", title: "Cable Trays", icon: "cable" },
                { name: "ElectricalConfig", title: "Electrical Config", svgIcon: assetUrl("icons/electrical-config.svg") }
            ]
        },
        { name: "Mounting", title: "Mounting", icon: "construction" },
        { name: "Consumption", title: "Consumption", icon: "electric_meter" },
        { name: "Battery", title: "Battery", icon: "battery_5_bar" },
        { name: "Finance", title: "Finance", svgIcon: assetUrl("icons/finance.svg") },
        { name: "Reports", title: "Reports", icon: "folder" },
        { name: "Permit", title: "Permit Package", icon: "assignment" }
    ];

    public hasLogo = false;

    private _activeName = "";

    private _stateCache: { [key: string]: boolean } = {};

    private _stateExpiry = 0;

    private _reviewedState = {
        report: false,
        consumption: false,
        battery: false
    };

    constructor(
        private _notify: NotifyService) {

        this.hasLogo = false;
    }

    public ngOnChanges(changes: SimpleChanges): void {
        if (this.mode && this.mode != this._activeName) {
            let item = this.menuItems.find(i => i.name == this.mode);
            if (!item) {
                // try find sub item
                for (const menuItem of this.menuItems) {
                    if (menuItem.children) {
                        for (const subItem of menuItem.children) {
                            if (subItem.name == this.mode) {
                                item = subItem;
                                break;
                            }
                        }
                    }
                }
            }

            if (item) {
                this.activateItem(item, true);
            }
        }
    }

    public async activateItem(item: MenuItem, skipTrigger?: boolean) {

        try {
            // deactivate others
            for (const item of this.menuItems) {
                item.active = false;
                item.expanded = false;
            }

            // get parent 
            const parent = this.menuItems.find(i => i.children && i.children.includes(item));
            if (parent && parent.children) {
                parent.expanded = true;
                // deactivate others
                for (const ch of parent.children) {
                    ch.active = false;
                }
            }

            item.active = true;
            item.expanded = true;

            if (item.children) {
                // deactivate sub items
                for (const ch of item.children) {
                    ch.active = false;
                }
            }

            this._activeName = item.name;

            if (!skipTrigger) {
                // send event
                this.itemActivated.emit(item.name);
            }

            if (item.name == "Reports") {
                this._reviewedState.report = true;
            }
            else if (item.name == "Consumption") {
                this._reviewedState.consumption = true;
            }
            else if (item.name == "Battery") {
                this._reviewedState.battery = true;
            }
        }
        catch (err) {
            this._notify.error(err);
        }
    }


    public activateMode(mode: AIKO.MenuMode) {

        // deactivate others
        for (const item of this.menuItems) {
            item.active = false;
            item.expanded = false;
        }


        // send event
        this.itemActivated.emit(mode);
    }

    public onBack() {
        this.back.emit();
    }

    public isStepDone(item: MenuItem) {
        if (!this.instance ||
            !this.project) {
            return false;
        }

        // use cache because angular will invoke the given functions many times
        if (this._stateExpiry > Date.now()) {
            const val = this._stateCache[item.name];
            if (typeof val == "boolean") {
                return val;
            }
        }
        else {
            this._stateCache = {};
            // update every 5 sec
            this._stateExpiry = Date.now() + 2000;
        }

        let isDone = false;

        if (item.name == "Design") {
            const modules = this.instance.getObjects({ types: ["module"] })
            isDone = modules.length > 0;
        }
        else if (item.name == "Electrical") {
            const strings = this.instance.getObjects({ types: ["string"] })
            if (strings.length) {
                isDone = true;
            }
        }
        else if (item.name == "Consumption") {
            if (this.project.electrical && this.project.electrical.battery) {
                isDone = true;
            }
            else if (this._reviewedState.consumption) {
                isDone = true;
            }
        }
        else if (item.name == "Mounting") {
            const segments = this.instance.getObjects({ types: ["segment"] }) as Solar.ObjectRooftopSegment[];
            for (const segment of segments) {
                if (segment.mountingSystem &&
                    segment.mountingSystem.railSpan) {
                    isDone = true;
                }
            }
        }
        else if (item.name == "Battery") {
            if (this.project.electrical && this.project.electrical.battery) {
                isDone = true;
            } else if (this._reviewedState.battery) {
                isDone = true;
            }
        }
        else if (item.name == "Simulation") {
            if (this.project.powerOutput && this.project.powerOutput.length) {
                isDone = true;
            }
        }
        else if (item.name == "Reports") {
            if (this.project.powerOutput &&
                this.project.powerOutput.length &&
                this._reviewedState.report) {
                isDone = true;
            }
        }

        this._stateCache[item.name] = isDone;

        return isDone;
    }

}
