<div class="component">

    <div class="buttons"
         [class.disabled]="!enabled">
        <div class="btn"
             (click)="createNew('rectangle')"
             [matTooltip]="'Drag \'rectangle\' to the map and click place' | translate">
            <app-icon [src]="'icons/rectangle.svg' | assetUrl"></app-icon>
            <div>
                {{"Rectangle" | translate}}
            </div>
        </div>

        <div class="btn"
             (click)="createNew('circle')"
             [matTooltip]="'Drag \'cylinder\' to the map and click place' | translate">
            <app-icon [src]="'icons/circle.svg' | assetUrl"></app-icon>
            <div>
                {{"Cylinder" | translate}}
            </div>
        </div>

        <div class="btn"
             (click)="createNew('custom')"
             [matTooltip]="'Click to customize the obstacle' | translate">
            <app-icon [src]="'icons/custom-tool.svg' | assetUrl"></app-icon>
            <div>
                {{"Custom" | translate}}
            </div>
        </div>
    </div>


    <div class="list-item"
         *ngFor="let keepout of keepouts">
        <div class="line highlight">
            <div class="title">
                <span (click)="toggle(keepout)"
                      class="title-text">
                    {{keepout.name}}
                </span>

                <button class="menu-btn"
                        mat-icon-button
                        (click)="onDelete(keepout)"
                        color="primary">
                    <mat-icon>delete</mat-icon>
                </button>

            </div>
            <div class="info"
                 (click)="toggle(keepout)">

                <div *ngIf="itemState[keepout.id]"
                     class="info-data">
                    <app-icon [src]="'icons/area.svg' | assetUrl"></app-icon>
                    {{getArea(keepout)}}m2
                </div>


                <div class="expander">
                    <mat-icon inline="true"
                              color="primary"
                              *ngIf="itemState[keepout.id]?.expanded">
                        expand_less
                    </mat-icon>
                    <mat-icon inline="true"
                              color="primary"
                              *ngIf="!itemState[keepout.id]?.expanded">
                        expand_more
                    </mat-icon>
                </div>
            </div>
        </div>

        <div *ngIf="itemState[keepout.id]?.expanded"
             class="item-content">

            <mat-form-field>
                <mat-label>{{'Name' | translate}}</mat-label>
                <input matInput
                       [placeholder]="'Enter the name' | translate"
                       [(ngModel)]="keepout.name">
            </mat-form-field>

            <ng-container *ngIf="keepout.keepoutType == 'rectangle'">
                <websolar-number-input [label]="'Length' | translate"
                                       [size]="'large'"
                                       [(value)]="keepout.length"
                                       (valueChange)="onItemChange(keepout)"></websolar-number-input>

                <websolar-number-input [label]="'Width' | translate"
                                       [size]="'large'"
                                       [(value)]="keepout.width"
                                       (valueChange)="onItemChange(keepout)"></websolar-number-input>

                <websolar-number-input [label]="'Height' | translate"
                                       [size]="'large'"
                                       [(value)]="keepout.height"
                                       (valueChange)="onItemChange(keepout)"></websolar-number-input>
            </ng-container>

            <ng-container *ngIf="keepout.keepoutType == 'circle'">
                <websolar-number-input [label]="'Radius' | translate"
                                       [size]="'large'"
                                       [(value)]="keepout.radius"
                                       (valueChange)="onItemChange(keepout)"></websolar-number-input>

                <websolar-number-input [label]="'Height' | translate"
                                       [size]="'large'"
                                       [(value)]="keepout.height"
                                       (valueChange)="onItemChange(keepout)"></websolar-number-input>
            </ng-container>

            <ng-container *ngIf="keepout.keepoutType == 'custom'">
                <websolar-number-input [label]="'Height' | translate"
                                       [size]="'large'"
                                       [(value)]="keepout.height"
                                       (valueChange)="onItemChange(keepout)"></websolar-number-input>
            </ng-container>

            <websolar-number-input [label]="'Setback' | translate"
                                   [size]="'large'"
                                   [(value)]="keepout.setback"
                                   (valueChange)="onItemChange(keepout)"></websolar-number-input>

            <div>
                <mat-checkbox [(ngModel)]="keepout.isVertical"
                              (ngModelChange)="onItemChange(keepout)">
                    {{"Is Vertical" | translate}}
                </mat-checkbox>
            </div>

        </div>
    </div>


</div>
