import * as THREE from "three";
import { Component, Input, OnChanges, OnDestroy } from '@angular/core';
import { SolarInstance, Solar, NotifyService, WebSolarEventsService, WebSolarTransactionService, WebSolarGeometryService } from '@websolar/ng-websolar';
import { Subscription } from 'rxjs';
import { Geometry } from 'src/app/core/geometry';
import { DialogService } from 'src/app/services/dialog.service';
import { AIKO } from "src/app/types/aiko.types";
import { ToolbarTool } from "src/app/core/toolbar.tool";
import { TranslateService } from "@ngx-translate/core";


@Component({
    selector: 'app-keepout-list',
    templateUrl: './keepout-list.component.html',
    styleUrls: ['./keepout-list.component.scss']
})
export class KeepoutListComponent implements OnChanges, OnDestroy {

    @Input() project!: Solar.Project;

    @Input() instance!: SolarInstance;

    @Input() toolbarControl!: AIKO.ToolbarControl;

    public enabled = false;

    public keepouts: Solar.ObjectKeepout[] = [];

    public itemState: { [key: string]: { expanded: boolean, item: Solar.ObjectKeepout } } = {};

    private _subs: Subscription[] = [];

    constructor(
        private _notify: NotifyService,
        private _dialogService: DialogService,
        private _eventService: WebSolarEventsService,
        private _transactionService: WebSolarTransactionService,
        private _translate: TranslateService
    ) {
        // subscribe to events
        const sub = this._eventService.eventsAsObservable.subscribe((opt) => {
            if (opt.name == "tool_completed" ||
                opt.name == "object_deleted" ||
                opt.name == "undo" ||
                opt.name == "redo" ||
                opt.name == "project_loaded") {
                this.updateList();
            }
            else if (opt.name == "object_picked") {
                this.onObjectPicked(opt.params as Solar.Object);
            }
        });
        this._subs.push(sub);
    }

    public ngOnChanges(): void {
        try {
            this.updateList();
        }
        catch (err) {
            console.error();
        }
    }

    public ngOnDestroy(): void {
        for (const sub of this._subs) {
            sub.unsubscribe();
        }
    }

    private onObjectPicked(object: Solar.Object) {
        try {
            if (!this.itemState[object.id]) {
                return;
            }

            // collapse other segments
            for (const key of Object.keys(this.itemState)) {
                if (key != object.id) {
                    this.itemState[key].expanded = false;
                }
            }

            this.itemState[object.id].expanded = true;
        }
        catch (err) {
            console.error(err);
        }
    }

    private updateList() {
        try {
            if (!this.instance) {
                return;
            }
            const prevItems = this.keepouts;

            this.keepouts = this.instance.getObjects({ types: ["keepout"] }) as Solar.ObjectKeepout[];

            for (const item of this.keepouts) {
                this.initObject(item);
            }

            if (prevItems && prevItems.length && prevItems.length < this.keepouts.length) {
                // probably new item added
                const last = this.keepouts[this.keepouts.length - 1];
                this.itemState[last.id].expanded = true;
            }

            if (this.keepouts.length == 1) {
                // expand it
                this.itemState[this.keepouts[0].id].expanded = true;
            }

            ToolbarTool.disableAll(this.toolbarControl);

            if (this.keepouts.length == 0) {
                this.instance.getHint().setMessage("Pick the obstacle/tree add to the map");
                this.toolbarControl.save = true;
                this.toolbarControl.zoom = true;
                this.toolbarControl.view3d = true;
                this.toolbarControl.undoRedo = true;
            }
            else {
                this.instance.getHint().setMessage("");
                this.toolbarControl.save = true;
                this.toolbarControl.zoom = true;
                this.toolbarControl.view3d = true;
                this.toolbarControl.ruler = true;
                this.toolbarControl.undoRedo = true;
                this.toolbarControl.delete = true;
                this.toolbarControl.copy = true;
            }

            // Only when the roof has been added,we can add the obstacle、keepout and arrange modules on the roof.
            const roofs = this.instance.getObjects({ types: ["roof"] });
            this.enabled = roofs.length > 0;
        }
        catch (err) {
            console.error();
        }
    }


    public toggle(keepout: Solar.ObjectKeepout) {
        if (!this.instance) {
            return;
        }

        this.itemState[keepout.id].expanded = !this.itemState[keepout.id].expanded;

        const obj3d = this.instance.get3dObjects({ id: keepout.id })[0];
        if (obj3d) {
            this.instance.objectEditor.attach(obj3d);
        }
    }

    /**
     * Initializes an object with the given item.
     */
    private initObject(item: Solar.ObjectKeepout) {
        if (!item.keepoutType) {
            item.keepoutType = 'custom';
        }
        const defaultToolName = this._translate.instant("Obstacle");
        if (!item.name || item.name == defaultToolName) {
            // set the default name
            switch (item.keepoutType) {
                case "rectangle":
                    item.name = this._translate.instant("Rectangle");
                    break;
                case "circle":
                    item.name = this._translate.instant("Cylinder");
                    break;
                default:
                    item.name = this._translate.instant("Custom");
                    break;
            }
        }
        this.itemState[item.id] = {
            expanded: false,
            item: item
        };
    }


    /**
     * Calculates the are
     */
    public getArea(item: Solar.ObjectKeepout): number {
        if (!item.keepoutType || item.keepoutType == "custom") {
            if (!item.points || !item.points.length) {
                return 0;
            }
            return Math.round(Geometry.getArea(item.points));
        }
        else {
            return Math.round((item.length || 0) * (item.width || 0));
        }
    }

    public async onDelete(item: Solar.ObjectKeepout) {
        try {
            const confirm = await this._dialogService.confirm({
                title: `Delete Obstable`,
                text: `Are you sure you want to delete this obstacle?`,
                okBtn: "Delete"
            })
            if (!confirm) {
                return;
            }
            // remove children
            this.instance.removeObjects({
                ownerId: item.id
            });

            // delete keepout as well
            this.instance.removeObjects({
                id: item.id
            });

            // remove from the list
            this.updateList();

            this.instance.rebuild(this.project);
        }
        catch (err) {
            this._notify.error(err);
        }
    }

    public onItemChange(keepout: Solar.ObjectKeepout) {
        if (!this.instance) {
            return;
        }
        if (keepout.setback) {
            keepout.setback = Math.max(keepout.setback, 0);
        }
        if (keepout.keepoutType == "rectangle") {
            if (keepout.length) {
                keepout.length = Math.max(keepout.length, 0.01);
            }
            if (keepout.width) {
                keepout.width = Math.max(keepout.width, 0.01);
            }
            keepout.points = this.getRectanglePoints(keepout);
        }
        else if (keepout.keepoutType == "circle") {
            if (keepout.radius) {
                keepout.radius = Math.max(keepout.radius, 0.01);
            }
            if (keepout.radius) {
                keepout.points = this.getCirclePoints(0, 0, keepout.radius, 50);
            }
        }

        this.update3dObject(keepout);

        this.instance.rebuild(this.project);
    }

    private update3dObject(keepout: Solar.ObjectKeepout) {
        if (!this.instance) {
            return;
        }

        // recreate a object on the drawing
        this.instance.removeObjects({ id: keepout.id });
        const newObj = this.instance.createObject(keepout, this.project.measurement);
        if (!newObj) {
            return;
        }
        this.instance.scene.add(newObj);
        return newObj;
    }

    /**
     * Creates a new keepout with the specified  type.
     */
    public createNew(keepoutType: Solar.KeepoutType) {
        if (!this.enabled) {
            return;
        }
        if (keepoutType == "custom") {
            // run the default tool
            this.instance.activateTool({ type: "keepout", params: null }, this.project);
        }
        else {
            this.runTool({ keepoutType: keepoutType })
        }
    }


    public async runTool(options: { keepoutType: Solar.KeepoutType }): Promise<Solar.ObjectKeepout | null> {
        const editTypes = this.instance.objectEditor.getEditTypes();
        try {
            // cancel the previous
            this.instance.sendEscape();

            // hide modules when I pick the retangele/cylinder obstacle
            this.instance.layers.modules = false;
            this.instance.layers.update();

            this.instance.objectEditor.disable();
            this.instance.objectTracker.disable();

            this.instance.getHint().setMessage("Pick the location")

            const solarObject = {
                id: this.instance.getUniqueId(),
                type: "keepout",
                keepoutType: options.keepoutType,
                isVertical: false,
                height: 1,
                points: [] as Solar.Point[],
                position: { x: 0, y: 0, z: 0 },
                rotation: { x: 0, y: 0, z: 0 }
            } as Solar.ObjectKeepout;

            if (options.keepoutType == "rectangle") {
                solarObject.width = 1;
                solarObject.length = 1;
                solarObject.height = 0.5;
                solarObject.points = this.getRectanglePoints(solarObject);
            }
            else if (options.keepoutType == "circle") {
                solarObject.radius = 0.3;
                solarObject.height = 1;
                // get circle points
                solarObject.points = this.getCirclePoints(0, 0, solarObject.radius, 50);
            }
            else {
                throw `unsupported keepout type`
            }

            const success = await this.processKeepout(solarObject);
            if (!success) {
                return null;
            }


            // add the transaction
            this._transactionService.add({
                type: "add",
                objects: [solarObject]
            });

            this.updateList();

            return solarObject;
        }
        finally {
            this.instance.getHint().setMessage("");
            this.instance.objectEditor.enable(editTypes);
            this.instance.objectTracker.enable();
            // restore the layers visibility
            this.instance.layers.modules = true;
            this.instance.layers.update();
        }
    }

    private getRectanglePoints(solarObject: Solar.ObjectKeepout): Solar.Point[] {
        if (!solarObject.length || !solarObject.width) {
            return [];
        }
        return [
            { x: -solarObject.length / 2, y: -solarObject.width / 2, z: 0 },
            { x: solarObject.length / 2, y: -solarObject.width / 2, z: 0 },
            { x: solarObject.length / 2, y: solarObject.width / 2, z: 0 },
            { x: -solarObject.length / 2, y: solarObject.width / 2, z: 0 }
        ];
    }

    private getCirclePoints(cx: number, cy: number, r: number, numberOfPoints: number): Solar.Point[] {
        const points: Solar.Point[] = [];
        for (let i = 0; i < numberOfPoints; i++) {
            const angle = (i / numberOfPoints) * 2 * Math.PI; // Convert point index to angle
            const x = cx + r * Math.cos(angle);
            const y = cy + r * Math.sin(angle);
            points.push({ x: x, y: y, z: 0 });
        }
        return points;
    }

    private async processKeepout(keepout: Solar.ObjectKeepout): Promise<boolean> {
        // add to scene
        const sceneObject = this.instance.createObject(keepout);
        if (!sceneObject) {
            return false;
        }

        this.instance.scene.add(sceneObject);

        const point = await this.instance.pointPicker.queryPoint({
            endPoint: null,
            disableSnaping: true,
            callback: (newPos: Solar.Point) => {
                const elevation = this.instance.surface.getElevationWithFilter(newPos.x, newPos.y, { types: ["roof"] }).elevation;
                keepout.position = { x: newPos.x, y: newPos.y, z: elevation };
                sceneObject.position.set(newPos.x, newPos.y, elevation);
            }
        }) as THREE.Vector3;

        if (!point) {
            this.instance.scene.remove(sceneObject);
            return false;
        }

        this.instance.rebuild(this.project);

        // enable transform
        this.instance.objectEditor.attach(sceneObject);
        return true;
    }
}
