import { Injectable } from "@angular/core";
import { Solar, WebSolarReportService } from "@websolar/ng-websolar";


@Injectable()
export class LossesService {

    constructor(
        private _reportService: WebSolarReportService
    ) { }

    /**
    * Defines custom losses for the AIKO application.
    * @returns The calculated energy after applying the custom losses.
    */
    public defineCustomLosses() {
        // AIKO has different formulas for calculation losses
        // so we should define custom formula here
        this._reportService.userDefinedLosses = (energy: number, project: Solar.Project) => {

            const systemLoses = project.systemLoses;
            if (!systemLoses) {
                return energy;
            }

            // apply irradiance
            if (systemLoses.soling) {
                energy = energy * (1 - (systemLoses.soling / 100))
            }

            // apply DC
            //
            if (systemLoses.snow) {
                energy = energy * (1 - (systemLoses.snow / 100))
            }
            if (project.basicLosses?.temperature) {
                energy = energy * (1 - (project.basicLosses.temperature / 100))
            }
            if (systemLoses.mismatch) {
                energy = energy * (1 - (systemLoses.mismatch / 100))
            }
            if (project.dcLosses) {
                energy = energy * (1 - (project.dcLosses.optimizer / 100))
                energy = energy * (1 - (project.dcLosses.voltageDrop / 100))
            }

            // apply AC
            if (project.dcLosses) {
                energy = energy * (1 - (project.dcLosses.inverter / 100))
            }
            if (systemLoses.wiring) {
                energy = energy * (1 - (systemLoses.wiring / 100))
            }
            if (systemLoses.availability) {
                energy = energy * (1 - (systemLoses.availability / 100))
            }

            return energy;
        }
    }
}
