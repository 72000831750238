<div class="component">

    <div class="buttons">
        <div class="btn"
             (click)="createNew('flat')"
             [matTooltip]="'Drag \'flat roof\' to the map and click place the roof' | translate">
            <app-icon [src]="'icons/flat-roof.svg' | assetUrl"></app-icon>
            <div>
                {{"Flat Roof" | translate}}
            </div>
        </div>

        <div class="btn"
             (click)="createNew('gable')"
             [matTooltip]="'Drag \'gable roof\' to the map and click place the roof' | translate">
            <app-icon [src]="'icons/gable-roof.svg' | assetUrl"></app-icon>
            <div>
                {{"Gable Roof" | translate}}
            </div>
        </div>

        <div class="btn"
             (click)="createNew('hipped')"
             [matTooltip]="'Drag \'hipped roof\' to the map and click place the roof' | translate">
            <app-icon [src]="'icons/hipped-roof.svg' | assetUrl"></app-icon>
            <div>
                {{"Hipped Roof" | translate}}
            </div>
        </div>

        <div class="btn"
             (click)="createNew('pointy')"
             [matTooltip]="'Drag \'pointy roof\' to the map and click place the roof' | translate">
            <app-icon [src]="'icons/pointy-roof.svg' | assetUrl"></app-icon>
            <div>
                {{"Pointy Roof" | translate}}
            </div>
        </div>

        <div class="btn"
             (click)="createNew('custom')"
             [matTooltip]="'Click to customize the roof' | translate">
            <app-icon [src]="'icons/custom-tool.svg' | assetUrl"></app-icon>
            <div>
                {{"Custom Roof" | translate}}
            </div>
        </div>
    </div>


    <div class="list-item"
         *ngFor="let roof of roofs">
        <div class="line highlight">
            <div class="title">
                <span (click)="toggle(roof)"
                      class="title-text">
                    {{roof.name}}
                </span>

                <button class="menu-btn"
                        mat-icon-button
                        (click)="onDelete(roof)"
                        color="primary">
                    <mat-icon>delete</mat-icon>
                </button>

            </div>
            <div class="info"
                 (click)="toggle(roof)">

                <div *ngIf="itemState[roof.id]"
                     class="info-data">
                    <app-icon [src]="'icons/area.svg' | assetUrl"></app-icon>
                    {{getArea(roof)}}m2
                </div>


                <div class="expander">
                    <mat-icon inline="true"
                              color="primary"
                              *ngIf="itemState[roof.id]?.expanded">
                        expand_less
                    </mat-icon>
                    <mat-icon inline="true"
                              color="primary"
                              *ngIf="!itemState[roof.id]?.expanded">
                        expand_more
                    </mat-icon>
                </div>
            </div>
        </div>

        <div *ngIf="itemState[roof.id]?.expanded"
             class="item-content">

            <mat-form-field>
                <mat-label>{{'Name' | translate}}</mat-label>
                <input matInput
                       [placeholder]="'Enter the name' | translate"
                       [(ngModel)]="roof.name">
            </mat-form-field>

            <ng-container *ngIf="roof.roofType == 'custom'">
                <div *ngFor="let edge of itemState[roof.id].customEdges; let idx = index"
                     class="edge-content"
                     [class.edge-highlight]="edge.highlight">
                    <div class="edge-lbl">{{'edge' | translate}}-{{idx+1}} (m)</div>
                    <div class="edge-params">
                        <div class="input-wrapper">
                            <app-icon [src]="'icons/edge-length.svg' | assetUrl"></app-icon>
                            <input type="number"
                                   min="0"
                                   [(ngModel)]="edge.length"
                                   (ngModelChange)="updateEdgeLength(roof, edge)"
                                   (focus)="activateEdge(roof, edge)">
                        </div>

                        <div class="input-wrapper">
                            <app-icon [src]="'icons/edge-height.svg' | assetUrl"></app-icon>
                            <input type="number"
                                   min="0"
                                   [(ngModel)]="edge.height"
                                   (ngModelChange)="updateEdgeHeight(roof, edge)"
                                   (focus)="activateEdge(roof, edge)">
                        </div>

                        <div class="edge-expander"
                             (click)="toggleEdge(roof, edge)">
                            <mat-icon inline="true"
                                      color="primary"
                                      *ngIf="!edge.expanded">
                                expand_less
                            </mat-icon>
                            <mat-icon inline="true"
                                      color="primary"
                                      *ngIf="edge.expanded">
                                expand_more
                            </mat-icon>
                        </div>
                    </div>
                    <div class="edge-params"
                         *ngIf="edge.expanded">
                        <div class="input-wrapper">
                            <app-icon [src]="'icons/edge-start-height.svg' | assetUrl"></app-icon>
                            <input type="number"
                                   min="0"
                                   [(ngModel)]="edge.startHeight"
                                   (ngModelChange)="updateEdgeStartHeight(roof, edge)"
                                   (focus)="activateEdge(roof, edge)">
                        </div>

                        <div class="input-wrapper">
                            <app-icon [src]="'icons/edge-end-height.svg' | assetUrl"></app-icon>
                            <input type="number"
                                   min="0"
                                   [(ngModel)]="edge.endHeight"
                                   (ngModelChange)="updateEdgeEndHeight(roof, edge)"
                                   (focus)="activateEdge(roof, edge)">
                        </div>
                    </div>
                </div>
            </ng-container>

            <ng-container *ngIf="roof.roofType != 'custom'">
                <websolar-number-input [label]="'Length' | translate"
                                       [size]="'large'"
                                       [(value)]="roof.length"
                                       (valueChange)="onPropertyChange(roof)"></websolar-number-input>

                <websolar-number-input [label]="'Width' | translate"
                                       [size]="'large'"
                                       [(value)]="roof.width"
                                       (valueChange)="onPropertyChange(roof)"></websolar-number-input>

                <websolar-number-input [label]="'Height' | translate"
                                       [size]="'large'"
                                       [(value)]="roof.height"
                                       (valueChange)="onPropertyChange(roof)"></websolar-number-input>
            </ng-container>

            <ng-container *ngIf="roof.roofType == 'hipped'">
                <websolar-number-input [label]="'Ridge Length' | translate"
                                       [size]="'large'"
                                       [(value)]="roof.ridgeLength"
                                       (valueChange)="onPropertyChange(roof)"></websolar-number-input>

                <websolar-number-input [label]="'Ridge Height' | translate"
                                       [size]="'large'"
                                       [(value)]="roof.ridgeHeight"
                                       (valueChange)="onPropertyChange(roof)"></websolar-number-input>
            </ng-container>

            <ng-container *ngIf="roof.roofType == 'gable'">
                <websolar-number-input [label]="'Ridge Height' | translate"
                                       [size]="'large'"
                                       [(value)]="roof.ridgeHeight"
                                       (valueChange)="onPropertyChange(roof)"></websolar-number-input>
            </ng-container>

            <ng-container *ngIf="roof.roofType == 'pointy'">
                <websolar-number-input [label]="'Tip Height' | translate"
                                       [size]="'large'"
                                       [(value)]="roof.ridgeHeight"
                                       (valueChange)="onPropertyChange(roof)"></websolar-number-input>
            </ng-container>


        </div>
    </div>


</div>
