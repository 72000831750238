import { Component, Input, OnChanges, SimpleChanges } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { WebSolarAuthService, NotifyService, WebSolarShareService, Solar } from '@websolar/ng-websolar';


@Component({
    selector: 'app-share-panel',
    templateUrl: './share-panel.component.html',
    styleUrls: ['./share-panel.component.scss']
})
export class SharingPanelComponent implements OnChanges {

    @Input() project!: Solar.Project;

    public link = "";

    public isLoading = false;

    public isPremium = false;

    constructor(
        private _auth: WebSolarAuthService,
        private _shareService: WebSolarShareService,
        private _notify: NotifyService,
        private _translate: TranslateService
    ) { }

    ngOnChanges(changes: SimpleChanges): void {
        if (this.project) {
            this.load();
        }
    }

    private async load() {
        try {
            this.isLoading = true;
            this.isPremium = this._auth.isPremium();

            if (this._auth.isSigned()) {
                // get the share link
                const lang = this._translate.currentLang;
                this.project.shareId = await this._shareService.share(this.project._id || "");
                this.link = `https://${document.location.host}/${lang || "en"}/solar/share/${this.project.shareId}`;
            }
        }
        catch (err) {
            this._notify.error(err);
        }
        finally {
            this.isLoading = false;
        }
    }

    public copyToClipboard() {
        // Copy the text inside the text field
        navigator.clipboard.writeText(this.link);
    }

}
